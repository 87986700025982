import React, { useState } from "react";
import "./signup.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { postFetch } from "../../functions/fetch/fetch.crud";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Signup = () => {
  const navigate = useNavigate();
  const [signUpDetails, setSignUpDetails] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
  });

  async function handleUpdateSignUpDetails(e) {
    // e.preventDefault();
    // console.log("signup function is invocked.");
    const property = e.target.name;
    const value = e.target.value;
    if (property === "mobileNumber") {
      // if(value.length)
      if (/^[0-9]$/.test(value.at(-1)) || value === "") {
        // if (/^[0-9]$/.test(value.toString().at(-1))) {
        setSignUpDetails({ ...signUpDetails, [property]: value });
      }
    } else {
      setSignUpDetails((prevValue) => {
        return { ...prevValue, [property]: value };
      });
    }
  }

  async function handleSignUp(e) {
    e.preventDefault();
    // setLoginDetails((prevVal) => {
    //   const newVal = { ...prevVal };
    //   const mobileNumber = Number(prevVal.mobileNumber);
    //   newVal.mobileNumber = mobileNumber;
    //   return newVal;
    // });
    const uploadData = { ...signUpDetails };
    const mobileNumber = Number(signUpDetails.mobileNumber);
    uploadData.mobileNumber = mobileNumber;
    const response = await postFetch("user/signup", uploadData);
    if (response.error) {
      console.log("signup error response =", response);
      console.log("type of response.error =", typeof response.error);
      // alert("Error : " + response.error);
      toast.error(
        typeof response.error === "object" ? response.error[0] : response.error
      );
      return;
    }

    console.log("response =", response);
    navigate("/login");
  }
  return (
    <div className="signup_main_full_div">
      <div className="signup_form_main_div">
        <form className="input_form_main_div">
          <div className="login_header">
            <h2 className="login-top-h1">Hello !</h2>
            <span>Welcome To Nidarshan</span>
          </div>
          <div className="signup_form_single_input">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={signUpDetails.name}
              onChange={handleUpdateSignUpDetails}
              autoComplete="userName"
            />
            <Icon className="login_fields_icon" icon={"mdi:user"} />
          </div>
          <div className="signup_form_single_input">
            <input
              type="text"
              name="mobileNumber"
              placeholder="Mobile Number"
              value={signUpDetails.mobileNumber}
              onChange={handleUpdateSignUpDetails}
              autoComplete="userMobile"
            />
            <Icon className="login_fields_icon" icon={"pajamas:mobile"} />
          </div>
          <div className="signup_form_single_input">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={signUpDetails.email}
              onChange={handleUpdateSignUpDetails}
              autoComplete="userEmail"
            />
            <Icon
              className="login_fields_icon"
              icon={"material-symbols:mail"}
            />
          </div>

          <div className="signup_form_single_input">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={signUpDetails.password}
              onChange={handleUpdateSignUpDetails}
              autoComplete="userPassword"
            />
            <Icon className="login_fields_icon" icon={"ic:round-lock"} />
          </div>
          <div className="verify_email_button_div">
            <button className="verify_email_button" onClick={handleSignUp}>
              Verify Email
            </button>
          </div>
          <div>
            <p className="not_a_user_login_page">
              Already a user?{" "}
              <span
                onClick={() => {
                  navigate("/login");
                }}
              >
                Sign Up Here
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
