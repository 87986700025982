import React, { useState } from "react";
import "./login.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import { loginPostFetch, postFetch } from "../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  // const [loginClicked, setLoginClicked] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    // email: "su1@gmail.com",
    // password: "u1",
    email: "su2@gmail.com",
    password: "u2",
    // email: "dummy2@gmail.com",
    // password: "dummy",
  });

  async function handleUpdateLoginDetails(e) {
    // e.preventDefault();
    const property = e.target.name;
    const value = e.target.value;
    setLoginDetails((prevValue) => {
      return { ...prevValue, [property]: value };
    });
  }

  async function handleLogin(e) {
    e.preventDefault();
    console.log(loginDetails);
    const response = await loginPostFetch("user/login", loginDetails);
    if (response) {
      // alert(JSON.stringify(response));
      if (response.error) {
        toast.error(response.error);
        console.log("response in error =", response);

        // alert("Error :", response.error);
        return;
      }
      console.log("response =", response);

      const { token, user } = response;

      localStorage.setItem("token", token);
      // localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("user", JSON.stringify(user));
      // navigate("/");
      window.location.href = "/";
      // }
    } else {
      toast.error("Can't login now.");
    }
  }

  return (
    <div className="login_main_parent_div">
      <div className="login_main_div">
        <div className="login_header">
          <h2 className="login-top-h1">Hello !</h2>
          <span>Welcome Back To Nidarshan</span>
        </div>
        <form className="input_login_form_main_div">
          <div className="user_name_div">
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={loginDetails.email}
              onChange={handleUpdateLoginDetails}
              autoComplete="userName"
            />
            <Icon className="login_fields_icon" icon={"mdi:user"} />
          </div>
          <div className="user_password_div">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={loginDetails.password}
              onChange={handleUpdateLoginDetails}
              autoComplete="current-password"
            />
            <Icon className="login_fields_icon" icon={"ic:round-lock"} />
          </div>
          <div className="forgot_password_div">
            <span>Forget Password</span>
          </div>
          <div className="submit_button">
            <button className="login_button" onClick={handleLogin}>
              Login
            </button>
          </div>
          <div>
            <p className="not_a_user_login_page">
              Not a user?{" "}
              <span
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Sign Up Here
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
