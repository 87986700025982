const generateArray = (size) => {
  return new Array(size).fill("_");
};

export const rollNumberArr = generateArray(10);
export const arrOfLength_10 = generateArray(10);

export const testSeriesArr = generateArray(8);

export const createOmrPageConstants = {
  50: {
    column: 5,
    rowInEachColumn: 10, // changes required from here.
    columnArr: generateArray(5),
    rowInEachColumnArr: generateArray(10), // changes required from here.
    optionArrWithoutOptionE: generateArray(4),
    optionArrWithOptionE: generateArray(5),
    rollNumberCircleWidth: "9px",
    testSeriesCircleWidth: "11px", // no change above sizes.
    eachColumnWidth: "78px",
    numberInColumnWidth: "16px",
    topColumnHeight: "11px",
    optionRowHeight: "20px", // changes required from here.
    optionsCircleWidth: "9px",
    optionRowNumberFontSize: "9px",
    optionRowOptionsFontSize: "4.5px",
    // eachColumnWidth: "65px",
    // numberInColumnWidth: "12px",
  },
  100: {
    column: 4,
    rowInEachColumn: 25,
    columnArr: generateArray(4),
    rowInEachColumnArr: generateArray(25),
    optionArrWithoutOptionE: generateArray(4),
    optionArrWithOptionE: generateArray(5),
    rollNumberCircleWidth: "9px",
    testSeriesCircleWidth: "11px",
    eachColumnWidth: "100px",
    numberInColumnWidth: "15px",
    topColumnHeight: "11px",
    optionRowHeight: "15px",
    optionsCircleWidth: "10px",
    optionRowNumberFontSize: "7px",
    optionRowOptionsFontSize: "6px",
    // eachColumnWidth: "65px",
    // numberInColumnWidth: "12px",
  },
  200: {
    column: 4,
    rowInEachColumn: 50,
    columnArr: generateArray(4),
    rowInEachColumnArr: generateArray(50),
    optionArrWithoutOptionE: generateArray(4),
    optionArrWithOptionE: generateArray(5),
    rollNumberCircleWidth: "9px",
    testSeriesCircleWidth: "11px", // no change above sizes.
    eachColumnWidth: "100px",
    numberInColumnWidth: "16px",
    topColumnHeight: "11px",
    optionRowHeight: "9px", // changes required from here.
    optionsCircleWidth: "8px",
    optionRowNumberFontSize: "6px",
    optionRowOptionsFontSize: "4.5px",
    // eachColumnWidth: "65px",
    // numberInColumnWidth: "12px",
  },
  250: {
    column: 5,
    rowInEachColumn: 50,
    columnArr: generateArray(5),
    rowInEachColumnArr: generateArray(50),
    optionArrWithoutOptionE: generateArray(4),
    optionArrWithOptionE: generateArray(5),
    rollNumberCircleWidth: "9px",
    testSeriesCircleWidth: "11px", // no change above sizes.
    eachColumnWidth: "78px",
    numberInColumnWidth: "16px",
    topColumnHeight: "11px",
    optionRowHeight: "9px", // changes required from here.
    optionsCircleWidth: "8px",
    optionRowNumberFontSize: "6px",
    optionRowOptionsFontSize: "4.5px",
    // eachColumnWidth: "65px",
    // numberInColumnWidth: "12px",
  },
  300: {
    column: 6,
    rowInEachColumn: 50,
    columnArr: generateArray(6),
    rowInEachColumnArr: generateArray(50),
    optionArrWithoutOptionE: generateArray(4),
    optionArrWithOptionE: generateArray(5),
    rollNumberCircleWidth: "9px",
    testSeriesCircleWidth: "11px", // no change above sizes.
    eachColumnWidth: "64px",
    numberInColumnWidth: "12px",
    topColumnHeight: "11px",
    optionRowHeight: "9px", // changes required from here.
    optionsCircleWidth: "8px",
    optionRowNumberFontSize: "5px",
    optionRowOptionsFontSize: "4.5px",
    // eachColumnWidth: "65px",
    // numberInColumnWidth: "12px",
  },
};
