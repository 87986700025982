import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const MarkHalfPieChart = ({
  PropertyValue,
  PropertyName,
  isPercentage = false,
}) => {
  const chartData = [
    { name: "right", value: parseFloat(PropertyValue.toFixed(2)) },
    { name: "wrong", value: parseFloat((100 - PropertyValue).toFixed(2)) },
  ];
  const chartColors = ["rgba(0, 197, 6 ,1)", "rgba(255, 0, 0 ,0.5)"];
  // console.log("welcome to result pie chart.");
  return (
    <div style={{ position: "relative", width: "250px", height: "120px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          <Pie
            data={chartData}
            startAngle={180}
            endAngle={0}
            dataKey="value"
            cx="50%"
            cy="90%"
            innerRadius={30}
            outerRadius={55}
            fill="#82ca9d"
            label
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={chartColors[index % chartColors.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>

        <span
          style={{
            position: "absolute",
            left: "50%",
            // left: "100px",
            bottom: "10px",
            transform: "translate(-50%, 0%)",
          }}
        >
          {PropertyValue} {isPercentage ? " %" : ""}
        </span>
      </ResponsiveContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "22px",
          fontWeight: 600,
        }}
      >
        {PropertyName}
      </div>
    </div>
  );
};

export default MarkHalfPieChart;
