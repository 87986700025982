const API_URL = process.env.REACT_APP_API_URL;

export const getHeaders = (headerData = null) => {
  const token = localStorage.getItem("token");
  // console.log("token in headers =", token);
  return {
    // ...(instituteId && { "x-database-name": instituteId }),
    ...(headerData && headerData),
    "Content-Type": "application/json",
    token,
    // Authorization: `Bearer ${token}`, // Or whatever header format you need
  };
};

export async function getFetch(url) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "GET",
    headers: getHeaders(),
    // body: JSON.stringify(data),
  });
  //   console.log("fetch response", response);
  if (response.status === 500) {
    return { error: "Internal Server Error" };
  } else {
    const res = await response.json();
    return res;
  }
}

export async function loginPostFetch(url, data) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "POST",
    // headers: getHeaders(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  if (response.status === 500) {
    return { error: "Internal Server Error" };
  } else {
    const res = await response.json();
    return res;
  }
}

export async function submitOmrPostFetch(url, data, errorText = false) {
  const response = await fetch(`${url}`, {
    method: "POST",
    // headers: getHeaders(),
    body: JSON.stringify(data),
  });
  console.log("main response in post request =", response);
  if (response.status === 500) {
    return { error: "Internal Server Error", details: response };
  } else {
    const res = await response.json();
    return res;
    // return { details: response, res };
  }
}

// const myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");
export async function postFetch(url, data, errorText = false) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  console.log("main response in post request =", response);
  if (response.status === 500) {
    return { error: "Internal Server Error" };
  } else {
    const res = await response.json();
    return res;
  }
  // console.log("main response in post request json =", response.json());
  // if (response.ok) {
  // } else {
  // return response.json();
  // }
  // if(response.status())
  // alert(errorText ? errorText : "Error While Creating.");

  // return false;
}

export async function updateFetch(url, data) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  console.log("main response in post request =", response);
  if (response.status === 500) {
    return { error: "Internal Server Error" };
  } else {
    const res = await response.json();
    return res;
  }
  // if (response.ok) return response.json();
  // // alert(errorText ? errorText : "Error While Creating.");
  // return false;
}

// // Update (PUT) function
// async function updateData(url, data) {
//   const response = await fetch(`${API_URL}${url}`, {
//     method: "PUT",
//     headers: getHeaders(),
//     body: JSON.stringify(data),
//   });
//   return response.json();
// }

// // Delete (DELETE) function
// async function deleteData(url) {
//   const response = await fetch(`${API_URL}${url}`, {
//     method: "DELETE",
//     headers: getHeaders(),
//   });
//   return response.json();
// }
