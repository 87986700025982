import React from "react";
import "./leftQuestionsDetails.css";
import { Icon } from "@iconify/react";
import { CbtLeftBottomButtonComponent } from "../../../../components/common/buttons/Button";
import CbtQuestionComponent from "./cbtQuestionComponent/CbtQuestionComponent";
import { SingleQuestionTimeTakenTimer } from "../../../../components/timer/Timer";

const LeftQuestionsDetails = ({
  isSmallMobile = false,
  subjectsList,
  // setsubjectsList,
  currentQuestion,
  handleSaveAndNext,
  currentQuestionNumber,
  handleChangeSubject,
  handleAnswerCurrentQuestion,
  currentQuestionAnswer,
  // handleAnswerTheQuestion,
  answers,
  setAnswers,
  handleClearResponse,
  handleMarkForReview,
}) => {
  // const [subjectsList, setsubjectsList] = useState({
  //   active: 0,
  //   arr: examSubjects,
  //   // [
  //   //   "Gujarati",
  //   //   "English",
  //   //   "Hindi",
  //   //   "Maths",
  //   //   "Science",
  //   //   "Social Science",
  //   //   "sanskrit",
  //   // ],
  // });

  // console.log("isSmallMobile = ", isSmallMobile);

  return (
    <div className="leftSideQuestionsDetailsComponentMainDiv">
      <div className="subjectsNameMainDiv">
        {subjectsList.arr.map((el, eli) => {
          return (
            <div
              key={eli}
              className={`${
                eli === subjectsList.active
                  ? eli === 0
                    ? "subjectNameActiveFirst"
                    : "subjectNameActive"
                  : eli === 0
                  ? "subjectNameFirst"
                  : "subjectName"
              }`}
              onClick={() => handleChangeSubject(eli, el)}
              // onClick={() => setsubjectsList({ ...subjectsList, active: eli })}
            >
              {el}
            </div>
          );
        })}
      </div>
      <div className="currentQuestionDetailMainDiv">
        {/* <div className="subjectsNameMainDiv">
          {subjectsList.arr.map((el, eli) => {
            return (
              <div
                key={eli}
                className={`${
                  eli === subjectsList.active
                    ? eli === 0
                      ? "subjectNameActiveFirst"
                      : "subjectNameActive"
                    : eli === 0
                    ? "subjectNameFirst"
                    : "subjectName"
                }`}
                onClick={() =>
                  setsubjectsList({ ...subjectsList, active: eli })
                }
              >
                {el}
              </div>
            );
          })}
        </div> */}
        <div className="questionNumber">
          <span>Que. : {currentQuestionNumber}</span>
          {/* <span>Question : 1</span> */}
          {/* <span>Question No.:1</span> */}
        </div>
        <div className="rightSideCurrentQuestionDetails">
          <div className="rightAnswerMarksOfCurrentQuestion">+1</div>
          <div className="wrongAnswerMarksOfCurrentQuestion">-0.33</div>
          <div className="timeUsedByCurrentQuestion">
            <Icon icon="mdi:clock-outline" />
            <SingleQuestionTimeTakenTimer
              currentQuestionNumber={currentQuestionNumber}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <Icon icon="charm:menu-kebab" />
        </div>
      </div>
      <div
        className={`${
          isSmallMobile
            ? "currentQuestionMainDivForMobile"
            : "currentQuestionMainDiv"
        }`}
      >
        <CbtQuestionComponent
          currentQuestion={currentQuestion}
          currentQuestionNumber={currentQuestionNumber}
          handleAnswerCurrentQuestion={handleAnswerCurrentQuestion}
          currentQuestionAnswer={currentQuestionAnswer}
          // handleAnswerTheQuestion={handleAnswerTheQuestion}
          // answers={answers}
        />
      </div>
      <div
        className={`${
          isSmallMobile ? "buttonsForCbtForMobile" : "buttonsForCbt"
        }`}
      >
        <div className="leftSideCbtButtons">
          <CbtLeftBottomButtonComponent
            buttonSpan={"Mark for preview & next"}
            onClickFunc={handleMarkForReview}
          />
          <CbtLeftBottomButtonComponent
            buttonSpan={"Clear response"}
            onClickFunc={handleClearResponse}
            // onClickFunc={handleClearResponseInChildren}
          />
        </div>
        <div className="rightSideCbtButtons">
          <CbtLeftBottomButtonComponent
            buttonSpan={"Save & Next"}
            onClickFunc={handleSaveAndNext}
          />
        </div>
      </div>
    </div>
  );
};

export default LeftQuestionsDetails;
