import React from "react";
// import { postFetch, updateFetch } from "../../../functions/fetch/fetch.crud";

export const BasicDetailsSection = ({
  currentTestSeries,
  expiryDate,
  handlePurchaseTestSeries,
  isTestSeriesPurchased,
  // loggedUser,
}) => {
  return (
    <>
      <section className="testSeriesPage_firstSection">
        <img
          className="testSeriesPage_img"
          src={currentTestSeries.posterPath}
          alt=""
        />
        <div
          className="testSeriesPage_1s_1d"
          style={{
            maxWidth: "calc(100% - 320px)",
            width: "90%",
            minWidth: "300px",
          }}
        >
          <p>Name : {currentTestSeries.name}</p>
          <p>Theme : {currentTestSeries.theme}</p>
          <p>
            Expiry : {expiryDate.getDate()}/{expiryDate.getMonth()}/
            {expiryDate.getFullYear()} at {expiryDate.getHours()}:
            {expiryDate.getMinutes()} {expiryDate.getHours() < 12 ? "AM" : "PM"}
          </p>
          <p>
            {/* <span style={{ marginRight: "20px" }}>
                  MRP : {currentTestSeries.mrp} ₹
                </span>{" "}
                &
                <span style={{ marginLeft: "20px" }}>
                  NSP : {currentTestSeries.nsp} ₹
                </span> */}
            <span style={{ marginRight: "10px" }}>Price :</span>
            {currentTestSeries.nsp ? (
              <>
                <span style={{ color: "green" }}>
                  {currentTestSeries.nsp} ₹
                </span>
                <span
                  style={{
                    // color: "green",
                    textDecoration: "line-through",
                    fontSize: "14px",
                    marginLeft: "5px",
                    fontWeight: "400",
                  }}
                >
                  {currentTestSeries.mrp} ₹
                </span>
                <span
                  className="priceSavePercentage"
                  style={{ marginLeft: "10px" }}
                >
                  ( Save Flat{" "}
                  {(
                    ((currentTestSeries.mrp - currentTestSeries.nsp) /
                      currentTestSeries.mrp) *
                    100
                  ).toFixed(2)}
                  % )
                </span>
              </>
            ) : (
              <span style={{ color: "green" }}>{currentTestSeries.mrp} ₹</span>
            )}
          </p>
          {/* <p>About : {currentTestSeries.about}</p> */}
          {/* <p>Description :</p> */}
          {/* <p
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "10px",
            }}
          > */}

          {console.log("isTestSeriesPurchased =", isTestSeriesPurchased)}

          {!(
            isTestSeriesPurchased
            // loggedUser.purchases?.some(
            //   (item) => item.offeringId === currentTestSeries._id
            // )
            // || loggedUser.purchases?.includes(currentTestSeries._id)
          ) && (
            // {loggedUser.purchases?.includes(currentTestSeries.id) && (
            <button className="primaryBtn" onClick={handlePurchaseTestSeries}>
              Purchase Now
            </button>
          )}
          {/* {currentTestSeries.isActive ? "Published" : "Not Published"}{" "}
            {!currentTestSeries.isActive && (
              <button
                className="primaryBtn"
                // onClick={async () => {
                //   // await updateFetch(
                //   const response = await postFetch(
                //     `testseries/dynamic/update/${currentTestSeries._id}`,
                //     { isActive: true }
                //   );
                //   console.log("response =", response);
                //   window.location.reload();
                // }}
              >
                Publish Now
              </button>
            )} */}
          {/* </p> */}
        </div>
      </section>
    </>
  );
};
