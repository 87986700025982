import { generateAllExamPapers } from "./tests";

export const testSeries1 = {
  ...(await generateAllExamPapers("ts1", 5)),
  mrp: 499,
  nsp: 399,
  theme: "omr",
  numberOfTests: "5",
  name: "gpsc class 1-2",
  // {tests , sections }: generateAllExamPapers("ts1", 5),
  about:
    "this is a good testseries for preperation of gpsc class 1-2. so all the best for your life. ldkfjsldf ",
  id: "ts1",
  poster: "https://i.ytimg.com/vi/xW3QzWPQmEk/sddefault.jpg?v=65962ec8",
  publishedBy: "ice rajkot",
  description:
    "this is about of the test series and it is a good test series for learn. earn and reach new heights in your life.",
}; //5
export const testSeries2 = {
  ...(await generateAllExamPapers("ts2", 3)),
  mrp: 499,
  nsp: 399, // nidarshan selling price.
  theme: "cbt",
  numberOfTests: "3",
  name: "cce",
  // tests: generateAllExamPapers("ts2", 3),
  about: "this is a good testseries for preperation of cce.",
  id: "ts2",
  poster:
    "https://static.gujaratsamachar.com/content_image/content_image_ca66001e-d6cc-4bfd-82d6-2b407c6f1cd2.jpeg",
  publishedBy: "kishwa career academy",
  description:
    "this is about of the test series and it is a good test series for learn. earn and reach new heights in your life.",
}; // 3
export const testSeries3 = {
  ...(await generateAllExamPapers("ts3", 7)),
  mrp: 499,
  // nsp:399,
  theme: "omr",
  numberOfTests: "7",
  name: "aimv",
  // tests: generateAllExamPapers("ts3", 7),
  about: "this is a good testseries for preperation of aimv.",
  id: "ts3",
  poster:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSLMGYiXlut1z_QCc08KyKEm4xdi5wbqtx-A&s",
  publishedBy: "Sankalp Education",
  description:
    "this is about of the test series and it is a good test series for learn. earn and reach new heights in your life.",
}; // 7

export const allTestSeries = [testSeries1, testSeries2, testSeries3];
