// import React, { useEffect, useState } from "react";
// import OmrQuestionComponent from "./components/OmrQuestionComponent";

// const OmrPage = ({ paper }) => {
//   const [screenSize, setScreenSize] = useState(
//     window.innerWidth > 610
//       ? {
//           width: "595px",
//           height: "842px",
//         }
//       : {
//           width: "95%",
//           height: `${Math.floor((595 * 842) / (window.innerWidth * 0.95))}px`,
//         }
//   );

//   useEffect(() => {
//     const handleResize = () => {
//       setScreenSize(() => {
//         return window.innerWidth > 610
//           ? {
//               width: "595px",
//               height: "842px",
//             }
//           : {
//               width: "95%",
//               height: `${Math.floor(
//                 (595 * 842) / (window.innerWidth * 0.95)
//               )}px`,
//             };
//       });
//     };
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const [questionsHeight, setQuestionsHeight] = useState({});
//   const [questionsInEachPage, setQuestionsInEachPage] = useState({});
//   const [currentPageHeight, setCurrentPageHeight] = useState(0);
//   const [PaperJsxComponent, setPaperJsxComponent] = useState([]);
//   const [isPaperSet, setIsPaperSet] = useState(false);

//   useEffect(() => {
//     let maxHeightOfQuestionInSinglePage = parseInt(screenSize.height) - 20;
//     let currentHeight = 0;
//     let currentPage = 0;
//     const pages = [[]];

//     paper.questions.forEach((element, index) => {
//       const questionHeight = 100;
//       if (currentHeight + questionHeight > maxHeightOfQuestionInSinglePage) {
//         currentPage += 1;
//         pages[currentPage] = [];
//         currentHeight = 0;
//       }
//       pages[currentPage].push(
//         <OmrQuestionComponent
//           key={index}
//           currentQuestion={element}
//           currentQuestionNumber={index + 1}
//           setQuestionsHeight={setQuestionsHeight}
//         />
//       );
//       currentHeight += questionHeight;
//     });

//     setPaperJsxComponent(
//       pages.map((page, pageIndex) => (
//         <div
//           key={pageIndex}
//           className="paperMainDiv"
//           style={{
//             "--paper-width": `${screenSize.width}`,
//             "--paper-height": `${screenSize.height}`,
//           }}
//         >
//           {page}
//         </div>
//       ))
//     );
//     setIsPaperSet(true);
//   }, [paper.questions, screenSize.height]);

//   return (
//     <div className="fullOmrPageMainDiv">{isPaperSet && PaperJsxComponent}</div>
//   );
// };

// export default OmrPage;
import React, { useEffect, useState } from "react";
import OmrQuestionComponent from "./components/omrQuestionComponent/OmrQuestionComponent";
import { CountDown } from "../../components/timer/Timer";
import { SimpleSubmitButton } from "../../components/common/buttons/Button";
import CreateOmrPage from "./components/createOmrPage/CreateOmrPage";
import { Icon } from "@iconify/react";
// import OmrQuestionComponent from "./components/omrQuestionComponent/OmrQuestionComponent";
import { useNavigate } from "react-router-dom";
import SubmitOmrPopop from "../../components/popup/SubmitOmrPopop";
import { postFetch, updateFetch } from "../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { logIn } from "../../redux/loggedUser/Slices";

const OmrPage = ({
  paper,
  exitFullScreen = () => {},
  isOfferingPurchased,
  loggedUserState,
  setLoggedUserState,
}) => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const [screenSize, setScreenSize] = useState(
    window.innerWidth > 610
      ? {
          width: "595px",
          height: "842px",
          innerWidth: "555px",
          innerHeight: "802px",
        }
      : {
          width: "95%",
          height: `${Math.floor((595 * 842) / (window.innerWidth * 0.95))}px`,
          innerWidth: `calc(95%)-40px`,
          // innerWidth: `${Math.floor(window.innerWidth * 0.95) - 40}px`,
          innerHeight: `${
            Math.floor((595 * 842) / (window.innerWidth * 0.95)) - 40
          }px`,
        }
  );
  const [submitPopup, setSubmitPopup] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(() => {
        return window.innerWidth > 610
          ? {
              width: "595px",
              height: "842px",
              innerWidth: "555px",
              innerHeight: "802px",
            }
          : {
              width: "95%",
              height: `${Math.floor(
                (595 * 842) / (window.innerWidth * 0.95)
              )}px`,
              innerWidth: `calc(95%)-40px`,
              innerHeight: `${
                Math.floor((595 * 842) / (window.innerWidth * 0.95)) - 40
              }px`,
            };
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log("paper in omr page =", paper);

  // paper main part.
  const [questionsHeight, setQuestionsHeight] = useState(
    paper.questionsHeight ? paper.questionsHeight : {}
  );
  // const [questionsHeight, setQuestionsHeight] = useState({});
  const [PaperJsxComponent, setPaperJsxComponent] = useState([]);
  const [isPaperSet, setIsPaperSet] = useState(false);
  // const [showOmr, setShowOmr] = useState(false);
  const [showOmr, setShowOmr] = useState(false);

  // save the answers
  const [answers, setAnswers] = useState({}); // visited, answer and marked

  function handleChangeAnswer(
    questionNumber,
    ans,
    updateOptionEFromOmr = false
  ) {
    // console.log("incoming details = ", questionNumber, " and ", ans);
    setAnswers((prevVal) => {
      let updatedAns = { ...prevVal };
      if (!updatedAns[questionNumber]) {
        updatedAns[questionNumber] = { answer: [ans] };
      } else if (!updatedAns[questionNumber].answer) {
        updatedAns[questionNumber].answer = [ans];
      } else if (updateOptionEFromOmr) {
        updatedAns[questionNumber].answer.push(ans);
      } else {
        updatedAns[questionNumber].answer = [ans];
      }
      // console.log("updated answers = ", JSON.stringify(updatedAns));
      return updatedAns;
    });
  }
  async function handleSubmitTest() {
    // try {
    exitFullScreen();
    // } catch {}

    const requestData = {
      userId: loggedUserState._id,
      offeringId: paper.offeringId,
      offeringType: paper.offeringType,
      testId: paper._id,
      numberOfAttempts: "",
      resultId: "",
      lastResultId: "",
      // result side
      studentAnswerData: answers,
      takenTime: 0,
      marks: 84.8,
      right: 90,
      wrong: 4,
      accuracy: 95.74, // right/(right+wrong or total selected questions)
    };

    console.log("sending result request =", requestData);

    const response = await updateFetch(
      `giventest/dynamic/add-test/${loggedUserState.givenTests._id}`,
      requestData
    );
    console.log("submit result response =", response);
    if (response.error) {
      toast.error(response.error);
      return;
    } else {
      toast.success(response.message);

      let olderUserValue = { ...loggedUserState };

      olderUserValue.givenTests = { ...olderUserValue.givenTests };

      const gtPush = response.givenTestsResponse.push;
      // const gtIndex = response.givenTestsResponse.index;
      // const gtIndex = response.givenTestsResponse.index;
      const gtData = response.givenTestsResponse.data;

      if (gtPush) {
        if (response.givenTestsResponse.pushAt === "givenTestsArray") {
          olderUserValue.givenTests.givenTestsArray = [
            ...olderUserValue.givenTests.givenTestsArray,
            gtData,
          ];
        } else if (
          response.givenTestsResponse.pushAt === "givenOfferingTests"
        ) {
          const innerPushIndex = response.givenTestsResponse.index;
          olderUserValue.givenTests.givenTestsArray[innerPushIndex] = [
            ...olderUserValue.givenTests.givenTestsArray[innerPushIndex],
            gtData,
          ];
        }
      } else {
        const gtIndex = response.givenTestsResponse.index;
        olderUserValue.givenTests.givenTestsArray = [
          ...olderUserValue.givenTests.givenTestsArray.slice(0, gtIndex),
          gtData,
          ...olderUserValue.givenTests.givenTestsArray.slice(gtIndex + 1),
        ];
      }
      console.log(
        "updated given tests =",
        olderUserValue.givenTests.givenTestsArray
      );
      setLoggedUserState({ ...olderUserValue });
      localStorage.setItem("user", JSON.stringify(olderUserValue));
      dispatch(logIn(olderUserValue));
      console.log("ready to navigate to home page.");
      // window.location.reload("/");
      navigate("/");
      return;
    }
  }

  async function handleAskToUploadOmrBeforeTest() {
    await handleSubmitTest();
    return;
  }

  useEffect(() => {
    if (
      Object.keys(questionsHeight).length === paper.questions.length &&
      !isPaperSet
    ) {
      let maxHeightOfQuestionInSinglePage = parseInt(screenSize.height) - 40;
      let currentHeight = 0;
      let currentPage = 0;
      const pages = [[]];

      paper.questions.forEach((element, index) => {
        const questionHeight = questionsHeight[index + 1] || 0; // Heights indexed by question number
        if (
          currentHeight + questionHeight > maxHeightOfQuestionInSinglePage &&
          currentHeight !== 0
        ) {
          currentPage += 1;
          pages[currentPage] = [];
          currentHeight = 0;
        }
        pages[currentPage].push(
          <OmrQuestionComponent
            key={index}
            currentQuestion={element}
            currentQuestionNumber={index + 1}
            // setQuestionsHeight={setQuestionsHeight}
            handleChangeAnswer={handleChangeAnswer}
            answers={answers}
          />
        );
        currentHeight += questionHeight;
      });

      setPaperJsxComponent(
        pages.map((page, pageIndex) => (
          <div
            key={pageIndex}
            className="paperMainDiv"
            style={{
              "--paper-width": `${screenSize.width}`,
              "--paper-height": `${screenSize.height}`,
              // width: `${screenSize.width}px`,
              // height: `${screenSize.height}px`,
            }}
          >
            {" "}
            <div
              className="paperInnerMainDiv"
              style={{
                "--paper-inner-width": `${screenSize.innerWidth}`,
                "--paper-inner-height": `${screenSize.innerHeight}`,
                // width: `${screenSize.width}px`,
                // height: `${screenSize.height}px`,
              }}
            >
              {page}
            </div>
          </div>
        ))
      );
      // console.log("total pages = ", pages.length);
      setIsPaperSet(true);
    }
  }, [
    paper.questions,
    screenSize.height,
    questionsHeight,
    screenSize.width,
    isPaperSet,
    screenSize.innerWidth,
    screenSize.innerHeight,
    answers,
  ]);

  return (
    <div className="fullOmrPageMainDiv">
      {showOmr && (
        <CreateOmrPage
          answers={answers}
          handleChangeAnswer={handleChangeAnswer}
          numberOfQuestions={paper.questions.length}
        />
      )}
      {/* omr page test name and time and show omr button top bar. */}
      <div className="omrExamTopBar">
        <div
          className="omrExamTopBarExamDetails"
          style={{
            width: screenSize.width,
            height: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span className="omrExamTopBarExamName">
            {/* {console.log("paper name =", paper.name)} */}
            {paper.name.length > 15
              ? paper.name.substring(0, 15) + "..."
              : paper.name}
          </span>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <CountDown
              timeInMinute={
                paper.duration.hour * 60 + paper.duration.minute
                // +
                // Math.floor(paper.duration.second / 60)
              }
              timeInSecond={paper.duration.second}
              isSmallMobile={true}
              handleSubmit={handleAskToUploadOmrBeforeTest}
              // handleSubmit={handleSubmitTest}
            />
            {/* <CountDown timeInMinute={180} isSmallMobile={true} /> */}

            <Icon
              icon={`${showOmr ? "maki:cross" : "gg:menu-grid-o"}`}
              className="showOmrIcon"
              onClick={() => {
                setShowOmr((prevValue) => {
                  return !prevValue;
                });
              }}
            />
          </div>
        </div>
      </div>
      {/* omr page omrs pages element. */}
      <div className="paperJsxParentMainDiv">
        {isPaperSet && PaperJsxComponent}
        {/* {!isPaperSet && (
          <div className="whiteBackGround">
            <div
              className="paperMainDiv fullyTransperantForPreRendering"
              style={{
                "--paper-width": `${screenSize.width}`,
                "--paper-height": `${screenSize.height}`,
                // width: `${screenSize.width}px`,
                // height: `${screenSize.height}px`,
              }}
            >
              <div
                className="paperInnerMainDiv"
                style={{
                  "--paper-inner-width": `${screenSize.innerWidth}`,
                  "--paper-inner-height": `${screenSize.innerHeight}`,
                }}
              >
                {paper.questions.map((el, eli) => {
                  return (
                    <OmrQuestionComponent
                      key={eli}
                      currentQuestion={el}
                      currentQuestionNumber={eli + 1}
                      setQuestionsHeight={setQuestionsHeight}
                      handleChangeAnswer={handleChangeAnswer}
                      answers={answers}
                    />
                    // <OmrQuestionComponent
                    //   key={eli}
                    //   currentQuestion={el}
                    //   currentQuestionNumber={eli + 1}
                    //   setQuestionsHeight={setQuestionsHeight}
                    // />
                  );
                })}
              </div>
            </div>
          </div>
        )} */}
      </div>
      {/* omr page bottom submit bar.  */}
      <div className="omrExamBottomBar">
        <SimpleSubmitButton
          buttonSpan={"submit test"}
          width={`${Math.floor(
            Math.min(595 * 0.8, window.innerWidth * 0.75)
          )}px`}
          height="30px"
          boxShadow={false}
          onClickFunc={handleAskToUploadOmrBeforeTest}
          // onClickFunc={handleSubmitTest}
        />
        {/* <button
          onClick={() => {
            window.scrollTo({
              top: 100000,
              left: 0,
              behavior: "smooth", // Add 'smooth' for smooth scrolling
            });
          }}
        >
          save and next
        </button> */}
        {/* {console.log("width = ", screenSize.width)}
        {console.log(
          "min width calculated = ",
          `${Math.floor(Math.min(595 * 0.8, window.innerWidth * 0.75))}px`
        )}
        {console.log(
          "submit button width = ",
          `${Math.floor(parseInt(screenSize.width) * 0.8)}px`
        )} */}
      </div>
      {submitPopup && <SubmitOmrPopop />}
    </div>
  );
};

export default OmrPage;
