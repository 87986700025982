import React from "react";

const SingleTestSeriesDetails = ({
  myTestSeries,
  handlePurchaseTestSeries,
  isTestSeriesPurchased,
  // loggedUser,
}) => {
  return (
    <>
      {/* <div className="singleTestSeriesTitle">{myTestSeries.name}</div> */}

      {/* <div className="singleTestSeriesTopImageDiv">
        <img
          src={myTestSeries.poster}
          alt=""
          className="singleTestSeriesTopImage"
        />
      </div> */}
      <div className="singleTestSeriesColumnFlex">
        <div className="singleTestSeriesRowFlexName">
          Made By :- {myTestSeries.publishedBy}
        </div>
      </div>
      {/* <div className="singleTestSeriesColumnFlex">
        <div className="singleTestSeriesRowFlexName">
          Theme :-{" "}
          <span style={{ textTransform: "uppercase" }}>
            {myTestSeries.theme}
          </span>
        </div>
      </div> */}
      {/* <div className="singleTestSeriesColumnFlex">
        <div className="singleTestSeriesRowFlexName">
          Number of tests :- {myTestSeries.numberOfTests}
        </div>
      </div> */}
      {/* <div className="singleTestSeriesColumnFlex">
        <div className="singleTestSeriesRowFlexName">
          {myTestSeries.nsp ? (
            <>
              Price : {myTestSeries.nsp} ₹{" "}
              <span
                className="singleTestSeriesPriceDeleteMsp"
                style={{ textDecoration: "line-through" }}
              >
                {myTestSeries.mrp} ₹
              </span>
            </>
          ) : (
            `Price : ${myTestSeries.mrp} ₹`
          )}
        </div>
      </div> */}
      <hr />
      <div className="singleTestSeriesColumnFlex">
        <div className="singleTestSeriesColumnFlexName">About :-</div>
        <div className="singleTestSeriesAbout">{myTestSeries.about}</div>
      </div>
      <hr />
      <div className="singleTestSeriesColumnFlex">
        <div className="singleTestSeriesColumnFlexName">Description :-</div>
        <div className="singleTestSeriesDescription">
          <div
            className="showRichText"
            dangerouslySetInnerHTML={{
              __html: myTestSeries.description,
            }}
          />
          {/* {myTestSeries.description} */}
        </div>
      </div>
      {!isTestSeriesPurchased && (
        // loggedUser.purchases?.some(
        //   (item) => item.offeringId === myTestSeries._id
        // )
        <div style={{ width: "100%" }}>
          <button
            className="primaryBtn"
            style={{ width: "80%", margin: "0 10% 10px" }}
            onClick={handlePurchaseTestSeries}
          >
            Purchase Now
          </button>
        </div>
      )}
      {/* <div className="singleTestSeriesurchaseNowDiv">
        <span >Purchase now</span>
      </div> */}
    </>
  );
};

export default SingleTestSeriesDetails;
