import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout/Layout";
// import CbtPage from "../pages/CbtPage/CbtPage";
import CbtMainPage from "../pages/CbtPage/CbtMainPage";
import OmrMainPage from "../pages/OmrPage/OmrMainPage";
import AllTestSeries from "../pages/TestSeries/AllTestSeries/AllTestSeries";
import SingleTestSeries from "../pages/TestSeries/SingleTestSeries/SingleTestSeries";
import SubmitOmrPopop from "../components/popup/SubmitOmrPopop";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import GlobalLayout from "../layout/GlobalLayour";
import Result from "../pages/Result/Result";
// import Home from "../pages/Home/Home";
// import CbtPage from "../pages/CbtPage/Page";

export const router = createBrowserRouter([
  // { path: "/", element: <Home /> },
  // {
  //   path: "/",
  //   // path: "/pages",
  //   // path: "/main",
  //   element: <Layout />,
  //   children: [
  //     { path: "/alltestseries", element: <AllTestSeries /> },
  //     { path: "/testseries", element: <SingleTestSeries /> },
  //     // { path: "/home", element: <Home /> },
  //     // { path: "cbt", element: <CbtPage /> },
  //     // { path: "layout/1", element: <CheckLoggedInOut /> },
  //     //   { path: "layout/2", element: <ReduxTry /> },
  //   ],
  // },
  // { path: "/cbt", element: <CbtMainPage /> },
  // { path: "/omr", element: <OmrMainPage /> },
  // { path: "/popup", element: <SubmitOmrPopop /> },
  // { path: "/login", element: <Login /> },
  // { path: "/signup", element: <Signup /> },
  {
    path: "/",
    element: <GlobalLayout />,
    children: [
      {
        path: "/",
        // path: "/pages",
        // path: "/main",
        element: <Layout />,
        children: [
          { path: "/alltestseries", element: <AllTestSeries /> },
          { path: "/testseries", element: <SingleTestSeries /> },
          // { path: "/home", element: <Home /> },
          // { path: "cbt", element: <CbtPage /> },
          // { path: "layout/1", element: <CheckLoggedInOut /> },
          //   { path: "layout/2", element: <ReduxTry /> },
          { path: "/result", element: <Result /> },
        ],
      },
      { path: "/cbt", element: <CbtMainPage /> },
      { path: "/omr", element: <OmrMainPage /> },
      { path: "/popup", element: <SubmitOmrPopop /> },
      { path: "/login", element: <Login /> },
      { path: "/signup", element: <Signup /> },
    ],
  },
]);
