import React from "react";
import "./numberBoxes.css";

export const RightNumberIcons = ({
  number,
  onClickFunc = () => {},
  // functionInputNumber,
}) => {
  return (
    <div className="rightNumberBoxMainDiv">
      <div className="rightNumberBox" onClick={onClickFunc}>
        {number}
      </div>
    </div>
  );
};
export const WrongNumberIcons = ({
  number,
  onClickFunc = () => {},
  // functionInputNumber,
}) => {
  return (
    <div className="wrongNumberBoxMainDiv">
      <div className="wrongNumberBox" onClick={onClickFunc}>
        {number}
      </div>
    </div>
  );
};
export const MarkedForReviewNumberIcons = ({
  number,
  onClickFunc = () => {},
  // functionInputNumber,
}) => {
  return (
    <div className="markedForReviewBoxMainDiv">
      <div className="markedForReviewBox" onClick={onClickFunc}>
        {number}
      </div>
    </div>
  );
};
export const AnsweredAndMarkedForReviewNumberIcons = ({
  number,
  onClickFunc = () => {},
  // functionInputNumber,
}) => {
  return (
    <div className="answeredAndMarkedForReviewBoxMainDiv">
      <div className="answeredAndMarkedForReviewBox" onClick={onClickFunc}>
        {number}
      </div>
    </div>
  );
};
export const NotVisitedNumberIcons = ({
  number,
  onClickFunc = () => {},
  // functionInputNumber,
}) => {
  return (
    <div className="notVisitedBoxMainDiv">
      <div className="notVisitedBox" onClick={onClickFunc}>
        {number}
      </div>
    </div>
  );
};
