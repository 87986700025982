import "./submitOmrPopop.css";
import React, { useState } from "react";
import { submitOmrPostFetch } from "../../functions/fetch/fetch.crud";
import { Popup } from "../common/popup-parent/Popup";
import { toast } from "react-toastify";
import { SmallPopup } from "../common/popup-parent/SmallPopup";

const SubmitOmrPopop = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseImage, setResponseImage] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [response, setResponse] = useState(null);
  //   let url = "https://mko7cpssv0.execute-api.ap-south-1.amazonaws.com/default";
  //   let url =
  //     "https://v9xsl02k9b.execute-api.ap-south-1.amazonaws.com/omr-scanning/";
  // let url =
  //   "https://v9xsl02k9b.execute-api.ap-south-1.amazonaws.com/omr-scanning/";
  // let tkn =
  //   "912da5e2018ccb841f4e4a9265d29f7394a052e20fbaa84262f8069cfcee1c32c01957af1d7c4ec9f3c654895a28153511875ebf3feb7496caf550abc713c9b81f297955c226ad50a0a32b05a9f09bd84bed1ebb94faba95c64a3d68eab3a8a679b3fc2323716050f5aefb420747268adcb837c2af415b41dc624a03b8605d9384332a5d8542e2d5b3e6fe6a84086d234b5197b7216d5fdc478d0e4956753f4792f8497247f660bf02690aa04b1fe10381151899bb0b24131e0a4c693f9df03548d13e5ab0bb6155bdd469c34dd87aee0161ebe3705f78550790074234b05fb84aee2b9b2519ba805b2c972eb2f70964";
  let tkn = localStorage.getItem("token");

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedFile) {
      try {
        const base64Image = await convertImageToBase64(selectedFile);

        // let config = {
        //   method: "post",
        //   maxBodyLength: Infinity,
        //   url: "https://v9xsl02k9b.execute-api.ap-south-1.amazonaws.com/omr-scanning/",
        //   headers: {
        //     "Content-Type": "text/plain",
        //   },
        //   data: JSON.stringify({ token: tkn, image: base64Image }),
        // };

        // axios
        //   .request(config)
        //   .then((response) => {
        //     console.log(JSON.stringify(response.data));
        //     setResponseImage("data:image/jpeg;base64," + response.data.image);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        const reqData = {
          token: tkn,
          image: base64Image,
        };
        setRequestInProgress(true);
        // const response = await fetch(process.env.OMR_SCAN_API_URL,reqData);
        console.log("uri =", process.env.REACT_APP_OMR_SCAN_API_URL);
        const response = await submitOmrPostFetch(
          process.env.REACT_APP_OMR_SCAN_API_URL,
          reqData
        );
        console.log("response from scan omr =", response);
        if (response.error) {
          toast.error(response.error);
          setRequestInProgress(false);
          return;
        }

        setRequestInProgress(false);
        setResponseImage("data:image/jpeg;base64," + response.opImg);
        setResponse(response);
        // setResponseImage("data:image/jpeg;base64," + base64Image);
        // setResponseImage("data:image/jpeg;base64," + response.data.image);
        return;
      } catch (error) {
        console.error("Error uploading image:", error);
        return;
      }
    }
  };

  return (
    <>
      {!responseImage && (
        <SmallPopup>
          <form onSubmit={handleSubmit}>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {!requestInProgress ? (
              <button type="submit" className="my5 primaryBtn">
                Submit
              </button>
            ) : (
              <p className="processingParagraph">
                <span className="primaryBtn">Processing...</span>
              </p>
            )}
          </form>
        </SmallPopup>
      )}
      {responseImage && (
        <Popup>
          <div>
            <h3>Response Image:</h3>
            <div className="WidthFull center">
              <img
                src={responseImage}
                style={{ width: "500px", height: "auto", maxWidth: "90%" }}
                alt="Response"
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
  // return (
  //   <div
  //     style={{
  //       width: "100vw",
  //       height: "100vh",
  //       zIndex: 1000,
  //       position: "absolute",
  //       top: 0,
  //       left: 0,
  //       backgroundColor: "white",
  //       display: "flex",
  //       justifyContent: "center",
  //       flexDirection: "column",
  //       alignItems: "center",
  //     }}
  //   >
  //     {/* <input type="file" name="" id="" onChange={handleOnChangeImageInput} />
  //     <img src={`data:image/jpeg;base64,${omrImage}`} alt="" width={"300px"} /> */}
  //     <form onSubmit={handleSubmit}>
  //       <input type="file" accept="image/*" onChange={handleFileChange} />
  //       <button type="submit">Submit</button>
  //     </form>
  //     {responseImage && (
  //       <div>
  //         <h3>Response Image:</h3>
  //         <img src={responseImage} width={300} height={400} alt="Response" />
  //       </div>
  //     )}
  //   </div>
  // );
};

export default SubmitOmrPopop;
