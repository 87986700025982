// import React from "react";
// import { VictoryLabel, VictoryPie } from "victory";

// const ResultPieChart = ({
// primaryColor = "rgba(0, 197, 6 ,1)",
// chartColors,
// centerLabel,
// chartData,
// }) => {
//   //   const Result = ({ primaryColor = "rgba(0, 197, 6 ,1)", centerLabel }) => {
//   // const colorScale = [primaryColor, "rgba(255, 0, 0 ,0.5)"];
//   //   const colorScale = ["rgba(0, 197, 6 ,1)", "rgba(255, 0, 0 ,0.5)"];
//   //   const colorScale = ["green", "red"];
//   return (
//     <div>
//       <div style={{ width: "150px", height: "150px" }}>
//         <svg viewBox="0 0 400 400">
//           <VictoryPie
//             standalone={false}
//             colorScale={chartColors}
//             width={400}
//             height={400}
//             data={chartData}
//             // data={[
//             //   { x: "A", y: 85 },
//             //   { x: "B", y: 15 },
//             //   //   { x: "C", y: 33 },
//             // ]}
//             innerRadius={80}
//             labels={() => ""}
//             // labels={({ datum }) => `${datum.y} %`}
//             cornerRadius={() => 3}
//             // for show data in limited angel
//             startAngle={-90}
//             endAngle={90}
//             // labelRadius={100}
//             // style={{ labels: { fontSize: "20px", fill: "black" } }}
//             // animate={{ duration: 2000, opacity: { from: 0.5, to: 1 } }}
//           />
//           {/* <circle
//             cx="200"
//             cy="200"
//             r="75"
//             fill="none"
//             stroke="black"
//             strokeWidth={3}
//           /> */}
//           {/* <circle
//             cx="200"
//             cy="200"
//             r="155"
//             fill="none"
//             stroke="black"
//             strokeWidth={3}
//           /> */}
//           <VictoryLabel
//             textAnchor="middle"
//             verticalAnchor="middle"
//             x={200}
//             y={180}
//             style={{ fontSize: "35px", fontWeight: 600 }}
//             text={centerLabel}
//           />
//         </svg>
//       </div>
//     </div>
//   );
// };

// export default ResultPieChart;
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const ResultPieChart = () => {
  const chartData = [
    { name: "right", value: 85.52, show: "15.41 %" },
    { name: "wrong", value: 15.41, show: "15.41 %" },
  ];
  const chartColors = ["rgba(0, 197, 6 ,1)", "rgba(255, 0, 0 ,0.5)"];
  // console.log("welcome to result pie chart.");
  return (
    <div style={{ position: "relative", width: "250px", height: "120px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          <Pie
            data={chartData}
            startAngle={180}
            endAngle={0}
            dataKey="value"
            cx="50%"
            cy="90%"
            innerRadius={30}
            outerRadius={55}
            fill="#82ca9d"
            label
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={chartColors[index % chartColors.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>

        <span
          style={{
            position: "absolute",
            left: "50%",
            // left: "100px",
            bottom: "10px",
            transform: "translate(-50%, 0%)",
          }}
        >
          85%
        </span>
      </ResponsiveContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "22px",
          fontWeight: 600,
        }}
      >
        Marks
      </div>
    </div>
  );
};

export default ResultPieChart;

// import React from "react";
// import {
//   BarChart,
//   Bar,
//   CartesianGrid,
//   XAxis,
//   YAxis,
//   ResponsiveContainer,
// } from "recharts";

// const ResultPieChart = () => {
//   // Sample data
//   const data = [
//     { name: "Geeksforgeeks", students: 400 },
//     { name: "Technical scripter", students: 700 },
//     { name: "Geek-i-knack", students: 200 },
//     { name: "Geek-o-mania", students: 1000 },
//   ];

//   return (
//     <ResponsiveContainer width="100%" height="100%">
//       <BarChart width={600} height={600} data={data}>
//         <Bar dataKey="students" fill="green" />
//         <CartesianGrid stroke="#ccc" />
//         <XAxis dataKey="name" />
//         <YAxis />
//       </BarChart>
//     </ResponsiveContainer>
//   );
// };

// export default ResultPieChart;
