const { toast } = require("react-toastify");
const { postFetch } = require("../fetch/fetch.crud");
// import Razorpay from "razorpay";

const loadRazorpayScriptFunction = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      // console.log("Razorpay SDK loaded successfully.");
      resolve(true);
    };
    script.onerror = (error) => {
      console.error("Failed to load Razorpay SDK:", error);
      reject(false);
    };
    document.body.appendChild(script);
  });
};

// async function makePaymentHandlerFunction(
//   userDetails,
//   amount,
//   userId,
//   offeringId,
//   instituteName,
//   institutePoster
// ) {
//   try {
//     console.log("welcome to payment function.");
//     // const amount = 10000;
//     // const currency = "INR";
//     const emailFirstPart = userDetails.email.split("@")[0];
//     const receiptId = `${emailFirstPart}-${offeringId}`;
//     console.log("receiptId =", receiptId);
//     // const receiptId = "user._id-offering._id";
//     const paymentResponse = await postFetch("payment/orderid", {
//       amount: amount * 100,
//       receipt: receiptId,
//     });
//     if (paymentResponse.error) {
//       console.log("error payment response =", paymentResponse);
//       toast.error("Error making payment.Try after some time.");
//       return false;
//     } else {
//       console.log("payment orderId response =", paymentResponse.orderId);

//       // Call Razorpay after getting the orderId
//       const res = await loadRazorpayScriptFunction();
//       if (!res) {
//         toast.error("Razorpay SDK failed to load. Are you online?");
//         return false;
//       }
//       // console.log("Razorpay SDK loaded Succeed.");
//       const options = {
//         // timeou,
//         key: process.env.RAZORPAY_KEY_ID, // Replace with your Razorpay key ID
//         amount: amount, // Amount is in the smallest currency unit (paise for INR)
//         currency: "INR",
//         name: instituteName, // receiver name
//         // name: "Testing", // receiver name
//         description: "Test Transaction",
//         // image: institutePoster,
//         image:
//           "https://plus.unsplash.com/premium_photo-1661421746164-b8b53de3bd4e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//         order_id: paymentResponse.orderId.id,
//         // handler: async function (response) {
//         //   // console.log("payment success response =", response);

//         //   const body = { ...response };

//         //   const validateResponse = await postFetch(
//         //     "payment/validate-payment",
//         //     body
//         //   );

//         //   if (validateResponse.error) {
//         //     console.log("payment validated error response =", validateResponse);
//         //     // toast.error("Error validating payment.Try after some time.");
//         //     alert("Payment Validation Failed.");
//         //     return false;
//         //   } else {
//         //     console.log("payment validated response =", validateResponse);
//         //     alert(validateResponse.message);
//         //     // toast.success(validateResponse.message);

//         //     return true;
//         //   }
//         //   // You can also make a call to your backend to verify the payment
//         //   // return;
//         // },
//         handler: async function (response) {
//           try {
//             const validateResponse = await postFetch(
//               "payment/validate-payment",
//               { ...response }
//             );

//             if (validateResponse.error) {
//               console.log(
//                 "Payment validation error response =",
//                 validateResponse
//               );
//               alert("Payment Validation Failed.");
//               return false;
//             } else {
//               console.log("Payment validated response =", validateResponse);
//               alert(validateResponse.message);
//               return true;
//             }
//           } catch (error) {
//             console.log("Error in payment handler:", error);
//             return false;
//           }
//         },

//         prefill: {
//           name: userDetails.name,
//           email: userDetails.email,
//           contact: userDetails.mobileNumber,
//         },
//         notes: {
//           address: "Company or Institute address.",
//         },
//         theme: {
//           color: "#3399cc",
//         },
//       };

//       const paymentResult = await new Promise((resolve, reject) => {
//         const paymentObject = new window.Razorpay(options);

//         paymentObject.on("payment.failed", function (response) {
//           console.log("Payment failed:", response.error);
//           alert("Payment failed. Please try again.");
//           reject(false);
//         });

//         paymentObject.open();

//         paymentObject.on("payment.success", function (response) {
//           console.log("response sending back to main function.");
//           resolve(true);
//         });
//       });
//       return paymentResult;
//       // return;
//     }
//   } catch (error) {
//     console.log("error while making payment.");
//     return false;
//   }
// }
async function makePaymentHandlerFunction(
  userDetails,
  amount,
  userId,
  offeringId,
  instituteName,
  institutePoster
) {
  try {
    console.log("Welcome to payment function.");
    const emailFirstPart = userDetails.email.split("@")[0];
    const receiptId = `${emailFirstPart}-${offeringId}`;
    console.log("receiptId =", receiptId);

    const paymentResponse = await postFetch("payment/orderid", {
      amount: amount * 100,
      receipt: receiptId,
    });

    if (paymentResponse.error) {
      console.log("Error payment response =", paymentResponse);
      toast.error("Error making payment. Try after some time.");
      return false;
    }

    console.log("Payment orderId response =", paymentResponse.orderId);

    const res = await loadRazorpayScriptFunction();
    if (!res) {
      toast.error("Razorpay SDK failed to load. Are you online?");
      return false;
    }

    const paymentResult = await new Promise((resolve, reject) => {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount,
        currency: "INR",
        name: instituteName,
        description: "Test Transaction",
        image:
          institutePoster ||
          "https://plus.unsplash.com/premium_photo-1661421746164-b8b53de3bd4e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        order_id: paymentResponse.orderId.id,
        handler: async function (response) {
          try {
            const validateResponse = await postFetch(
              "payment/validate-payment",
              { ...response }
            );

            if (validateResponse.error) {
              console.log(
                "Payment validation error response =",
                validateResponse
              );
              alert("Payment Validation Failed.");
              reject(false);
            } else {
              console.log("Payment validated response =", validateResponse);
              resolve(true);
            }
          } catch (error) {
            console.log("Error in payment handler:", error);
            reject(false);
          }
        },
        prefill: {
          name: userDetails.name,
          email: userDetails.email,
          contact: userDetails.mobileNumber,
        },
        notes: {
          address: "Company or Institute address.",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.failed", function (response) {
        console.log("Payment failed:", response.error);
        alert("Payment failed. Please try again.");
        reject(false);
      });

      paymentObject.open();
    });

    return paymentResult;
  } catch (error) {
    console.log("Error while making payment.", error);
    return false;
  }
}

module.exports = { makePaymentHandlerFunction };
