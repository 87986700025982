import React from "react";
import "./testSeriesCard.css";
import { useNavigate } from "react-router-dom";

const TestSeriesCard = ({ element }) => {
  const navigate = useNavigate();

  function handleNavigateToPath(path, id) {
    navigate(`${path}?id=${id}`);
  }
  return (
    <div className="TestSeriesCard">
      <div className="TestSeriesCardImageDiv">
        <img src={element.posterPath} alt="" className="TestSeriesCardImage" />
      </div>
      <p className="TestSeriesCardName">
        {element.name.length > 15
          ? element.name.substring(0, 15) + "..."
          : element.name}
      </p>
      <p className="TestSeriesCardDescription">
        {/* {element.description} */}
        {element.about.length > 75
          ? element.about.substring(0, 75) + "..."
          : element.about}
      </p>
      <hr style={{ opacity: "0.5", width: "80%", margin: "auto" }} />
      <div className="TestSeriesCardNumberOfTestsDiv">
        <span>
          Theme :{" "}
          <span
            className="TestSeriesCardNumberOfTestsSpan"
            style={{ textTransform: "uppercase" }}
          >
            {element.theme}
          </span>
        </span>
        {/* <span>
          Number Of Tests :{" "}
          <span className="TestSeriesCardNumberOfTestsSpan">
            {element.numberOfTests}
          </span>
        </span> */}
      </div>
      <p className="TestSeriesCardPublishedBy">By : {element.publishedBy}</p>
      <p className="TestSeriesCardPrice">
        {/* Price : {element.price} ₹ */}
        {element.nsp ? (
          <>
            Price : {element.nsp} ₹{" "}
            <span
              className="TestSeriesCardPriceDeleteMsp"
              style={{ textDecoration: "line-through" }}
            >
              {element.mrp} ₹
            </span>
          </>
        ) : (
          `Price : ${element.mrp} ₹`
        )}
      </p>
      <div style={{ width: "100%" }}>
        <button
          className="primaryBtn"
          style={{ width: "80%", margin: "0 10%" }}
          // className="TestSeriesCardViewMoreButton"
          onClick={() => handleNavigateToPath("/testseries", element._id)}
        >
          <p>View More</p>
        </button>
      </div>
    </div>
  );
};

export default TestSeriesCard;
