import React, { useEffect, useState } from "react";
import "./cbtPage.css";
import LeftQuestionsDetails from "./components/leftQuestionDetails/LeftQuestionsDetails";
import RightQuestionNumberDetails from "./components/rightQuestionNumbers/RightQuestionNumberDetails";
// import { CountDown } from "./components/timer/Timer";
import { Icon } from "@iconify/react";
import { CountDown } from "../../components/timer/Timer";
// import { cbtExamPaper } from "./components/cbtConstants/cbtPaper";
import { useNavigate } from "react-router-dom";
import { postFetch, updateFetch } from "../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";
import { logIn } from "../../redux/loggedUser/Slices";
import { useDispatch } from "react-redux";

const CbtPage = ({
  paper,
  exitFullScreen = () => {},
  isOfferingPurchased,
  loggedUserState,
  setLoggedUserState,
}) => {
  const dispatch = useDispatch();
  const [
    isShowSmallMobileRightQuestionNumbers,
    setIsShowSmallMobileRightQuestionNumbers,
  ] = useState(false);
  const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth <= 500);
  const [isFullScreenDevice, setIsFullScreenDevice] = useState(
    window.innerWidth >= 1024
  );
  const navigate = useNavigate();
  // console.log("paper in omr page =", paper);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallMobile(window.innerWidth <= 500);
      setIsFullScreenDevice(window.innerWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isSmallMobile, isFullScreenDevice]);

  // paper use states
  const [showPage, setshowPage] = useState(false);
  const [stopEffectAsValuesSet, setstopEffectAsValuesSet] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState("");
  const [currentSubjectQuestionNumbers, setCurrentSubjectQuestionNumbers] =
    useState([]);
  const [
    numberOfQuestionInSubjectGroupArrangement,
    setNumberOfQuestionInSubjectGroupArrangement,
  ] = useState([]);
  const [subjectsList, setsubjectsList] = useState({
    active: 0,
    arr: [],
  });
  const [answers, setAnswers] = useState({}); // visited, answer and marked

  // api sample data
  // const paper = cbtExamPaper;

  // functions
  const handleGetCurrentActiveSubject = (questionNumber) => {
    return (
      numberOfQuestionInSubjectGroupArrangement.findIndex(
        (number) => number >= questionNumber
      ) - 1
    );
  };

  const handleGetCurrentActiveSubjectQuestionNumbers = (
    activeNumber = subjectsList.active
  ) => {
    let array = [
      numberOfQuestionInSubjectGroupArrangement[activeNumber],
      numberOfQuestionInSubjectGroupArrangement[activeNumber + 1],
    ];
    setCurrentSubjectQuestionNumbers(() => {
      return array;
    });
  };

  const handleChangeSubject = (activeNumber, activeName) => {
    if (subjectsList.active !== activeNumber) {
      setsubjectsList({ ...subjectsList, active: activeNumber });
      handleGetCurrentActiveSubjectQuestionNumbers(activeNumber);
      const indexOfSubject = subjectsList.arr.indexOf(activeName);
      setCurrentQuestionNumber(
        numberOfQuestionInSubjectGroupArrangement[indexOfSubject] + 1
      );
      setCurrentQuestion(
        paper.questions[
          numberOfQuestionInSubjectGroupArrangement[indexOfSubject]
        ]
      );
      setCurrentQuestionAnswer((prevState) => {
        let newQuestionNumber =
          numberOfQuestionInSubjectGroupArrangement[indexOfSubject] + 1;
        const answerPresentCondition = newQuestionNumber in answers;
        return answerPresentCondition
          ? answers[newQuestionNumber]
            ? answers[newQuestionNumber].answer
              ? answers[newQuestionNumber].answer[0]
              : ""
            : ""
          : "";
      });
      setAnswers((prevState) => {
        const updatedAnswers = { ...prevState };

        let number =
          numberOfQuestionInSubjectGroupArrangement[indexOfSubject] + 1;

        if (!(number in updatedAnswers)) {
          updatedAnswers[number] = {};
        }
        updatedAnswers[number]["visited"] = true;

        return updatedAnswers;
      });
    }
  };

  const handleChangeQuestionNumberOfSameSubject = (number) => {
    // handleToggleFullScreen(number % 2 === 0 ? "enter" : "exit");
    // alert("Showing alert for number = ", number);
    // handleEnterFullScreen();
    if (!(currentQuestionNumber === number)) {
      setCurrentQuestionNumber(() => {
        return number;
      });
      setCurrentQuestion(paper.questions[number - 1]);
      setCurrentQuestionAnswer((prevState) => {
        let newQuestionNumber = number;
        const answerPresentCondition = newQuestionNumber in answers;

        return answerPresentCondition
          ? answers[newQuestionNumber]
            ? answers[newQuestionNumber].answer
              ? answers[newQuestionNumber].answer[0]
              : ""
            : ""
          : "";
      });
      setAnswers((prevState) => {
        const updatedAnswers = { ...prevState };

        if (!(number in updatedAnswers)) {
          updatedAnswers[number] = {};
        }
        updatedAnswers[number]["visited"] = true;

        return updatedAnswers;
      });
    }
  };

  const handleClearResponse = () => {
    if (answers[currentQuestionNumber]?.answer) {
      setCurrentQuestionAnswer((prevState) => {
        return "";
      });
    }
  };

  const handleAnswerTheQuestion = (
    questionNumber,
    ans,
    newQuestionNumber,
    marked = false
  ) => {
    setAnswers((prevState) => {
      const updatedAnswers = { ...prevState };

      updatedAnswers[questionNumber]["answer"] = [ans];

      if (!(newQuestionNumber in updatedAnswers)) {
        updatedAnswers[newQuestionNumber] = {};
      }
      updatedAnswers[newQuestionNumber]["visited"] = true;
      if (marked) {
        updatedAnswers[questionNumber]["marked"] = updatedAnswers[
          questionNumber
        ]["marked"]
          ? !updatedAnswers[questionNumber]["marked"]
          : true;
      }

      // console.log(
      //   "updated answer in save and next = ",
      //   JSON.stringify(updatedAnswers)
      // );

      return updatedAnswers;
    });
  };

  const handleAnswerCurrentQuestion = (currentAnswer) => {
    setCurrentQuestionAnswer((prevState) => {
      return currentAnswer;
    });
  };

  const handleSaveAndNext = (marked = false) => {
    if (paper.numberOfQuestions <= currentQuestionNumber) {
      handleAnswerTheQuestion(
        currentQuestionNumber,
        currentQuestionAnswer,
        1,
        marked
      );
      setCurrentQuestionNumber(1);
      setCurrentQuestion(paper.questions[0]);
      setsubjectsList({ ...subjectsList, active: 0 });
      handleGetCurrentActiveSubjectQuestionNumbers(0);
      setCurrentQuestionAnswer((prevState) => {
        let newQuestionNumber = 1;
        const answerPresentCondition = newQuestionNumber in answers;

        return answerPresentCondition
          ? answers[newQuestionNumber]
            ? answers[newQuestionNumber].answer
              ? answers[newQuestionNumber].answer[0]
              : ""
            : ""
          : "";
      });
    } else {
      let newUpdatedQuestionNumber = currentQuestionNumber + 1;
      handleAnswerTheQuestion(
        currentQuestionNumber,
        currentQuestionAnswer,
        newUpdatedQuestionNumber,
        marked
      );
      setCurrentQuestion(paper.questions[currentQuestionNumber]);
      setCurrentQuestionNumber(currentQuestionNumber + 1);
      let currGroupIndex = handleGetCurrentActiveSubject(
        currentQuestionNumber + 1
      );
      if (currGroupIndex !== subjectsList.active) {
        setsubjectsList({ ...subjectsList, active: currGroupIndex });
        handleGetCurrentActiveSubjectQuestionNumbers(currGroupIndex);
      }
      setCurrentQuestionAnswer((prevState) => {
        let newQuestionNumber = currentQuestionNumber + 1;
        const answerPresentCondition = newQuestionNumber in answers;
        return answerPresentCondition
          ? answers[newQuestionNumber]
            ? answers[newQuestionNumber].answer
              ? answers[newQuestionNumber].answer[0]
              : ""
            : ""
          : "";
      });
    }
  };

  const handleMarkForReview = () => {
    handleSaveAndNext(true);
  };

  const handleSubmit = async () => {
    exitFullScreen();
    console.log("answers =", answers);
    const requestData = {
      userId: loggedUserState._id,
      offeringId: paper.offeringId,
      offeringType: paper.offeringType,
      testId: paper._id,
      numberOfAttempts: "",
      resultId: "",
      lastResultId: "",
      // result side
      studentAnswerData: answers,
      takenTime: 0,
      marks: 84.8,
      right: 90,
      wrong: 4,
      accuracy: 95.74, // right/(right+wrong or total selected questions)
    };

    console.log("sending result request =", requestData);

    const response = await updateFetch(
      `giventest/dynamic/add-test/${loggedUserState.givenTests._id}`,
      requestData
    );
    console.log("submit result response =", response);
    if (response.error) {
      toast.error(response.error);
      return;
    } else {
      toast.success(response.message);

      let olderUserValue = { ...loggedUserState };

      olderUserValue.givenTests = { ...olderUserValue.givenTests };

      const gtIndex = response.givenTestsResponse.index;
      const gtData = response.givenTestsResponse.data;

      if (gtIndex === "length") {
        olderUserValue.givenTests.givenTestsArray = [
          ...olderUserValue.givenTests.givenTestsArray,
          gtData,
        ];
      } else {
        olderUserValue.givenTests.givenTestsArray = [
          ...olderUserValue.givenTests.givenTestsArray.slice(0, gtIndex),
          gtData,
          ...olderUserValue.givenTests.givenTestsArray.slice(gtIndex + 1),
        ];
      }
      console.log(
        "updated given tests =",
        olderUserValue.givenTests.givenTestsArray
      );
      setLoggedUserState({ ...olderUserValue });
      localStorage.setItem("user", JSON.stringify(olderUserValue));
      dispatch(logIn(olderUserValue));
      navigate("/");
      return;
    }
    // const response = await postFetch("result/submit-test", requestData);
    // console.log("response =", response);
    // return;
    // update this for result.
    // navigate("/");
  };

  // // full screen functions.
  // function handleEnterFullScreen() {
  //   const elem = document.documentElement;
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen().catch((err) => {
  //       console.error("Failed to enter full screen:");
  //       console.log("Error = ", err);
  //     });
  //   } else if (elem.mozRequestFullScreen) {
  //     elem.mozRequestFullScreen();
  //   } else if (elem.webkitRequestFullscreen) {
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) {
  //     elem.msRequestFullscreen();
  //   }
  // }

  // function handleExitFullScreen() {
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen().catch((err) => {
  //       console.error("Failed to exit full screen:", err);
  //     });
  //   } else if (document.mozCancelFullScreen) {
  //     document.mozCancelFullScreen();
  //   } else if (document.webkitExitFullscreen) {
  //     document.webkitExitFullscreen();
  //   } else if (document.msExitFullscreen) {
  //     document.msExitFullscreen();
  //   }
  // }

  // function handleToggleFullScreen(process = "toggle") {
  //   // below will help us to check if developer menu is opened or not.
  //   // const isFullScreen =
  //   //   window.outerHeight + 14 === window.innerHeight &&
  //   //   window.outerWidth + 14 === window.innerWidth;
  //   const isFullScreen =
  //     document.fullscreenElement ||
  //     document.mozFullScreenElement ||
  //     document.webkitFullscreenElement ||
  //     document.msFullscreenElement;

  //   console.log("isFullScreen = ", isFullScreen);
  //   console.log(
  //     "inner width and height = ",
  //     window.innerWidth,
  //     " and ",
  //     window.innerHeight
  //   );
  //   console.log(
  //     "outer width and height = ",
  //     window.outerWidth,
  //     " and ",
  //     window.outerHeight
  //   );
  //   if (
  //     window.outerHeight + 14 === window.innerHeight &&
  //     window.outerWidth + 14 === window.innerWidth
  //   )
  //     //   alert("you are in full screen.");
  //     console.log("you are in full screen.");

  //   if (process === "enter") {
  //     handleEnterFullScreen();
  //   } else if (process === "exit") {
  //     if (isFullScreen) handleExitFullScreen();
  //   } else if (process === "toggle") {
  //     if (!isFullScreen) {
  //       // Enter full screen modessssssssss
  //       handleEnterFullScreen();
  //     } else {
  //       // Exit full screen mode
  //       handleExitFullScreen();
  //     }
  //   }
  // }

  useEffect(() => {
    // function disableKeyboard(event) {
    //   console.log("event key = ", event.key);
    //   if (event.key === "F11") {
    //     console.log("this key is disabled.");
    //     event.preventDefault(); // Prevent default keyboard behavior
    //     event.stopPropagation(); // Stop event propagation
    //   }
    // }

    // // Add the event listener
    // document.addEventListener("keydown", disableKeyboard);
    if (!stopEffectAsValuesSet) {
      // handleEnterFullScreen("enter");
      let examSubjectsArr = [];
      let numberOfQuestionInSubjectGroupArrangementArr = [];
      let previousQuestionCompleted = 0;
      paper.groups.forEach((element) => {
        examSubjectsArr.push(element.name);
        numberOfQuestionInSubjectGroupArrangementArr.push(
          previousQuestionCompleted
        );
        previousQuestionCompleted =
          previousQuestionCompleted + element.numberOfQuestions;
      });

      setsubjectsList({ active: 0, arr: examSubjectsArr });

      setCurrentSubjectQuestionNumbers(() => {
        return [0, paper.groups[0].numberOfQuestions];
      });

      setCurrentQuestion(paper.questions[0]);
      setNumberOfQuestionInSubjectGroupArrangement([
        ...numberOfQuestionInSubjectGroupArrangementArr,
        paper.numberOfQuestions,
      ]);

      setAnswers(() => {
        let obj = {
          1: {
            visited: true,
          },
        };
        return obj;
      });

      // required to make a different function.
      setstopEffectAsValuesSet(true);
      setshowPage(true);
      document.addEventListener("contextmenu", function (event) {
        // Prevent the default browser context menu from appearing
        event.preventDefault();
      });

      // document.addEventListener("mousedown", function (event) {
      //   // Log a message indicating that a button on the mouse is being pressed
      //   // console.log("Pressing button from mouse");

      //   // // Log which button is being pressed
      //   // console.log("Button pressed:", event.button);
      //   if (event.button !== 0) {
      //     console.log("button another then left.");
      //     event.preventDefault();
      //   }
      // });
    }
  }, [paper, stopEffectAsValuesSet, currentQuestionNumber]);

  return (
    <>
      {showPage && (
        <div className="fullCbtPageMainDiv">
          <div className="topTitleMainDiv">
            <div className="topTitleDiv" style={{ width: "calc(100%-20px)" }}>
              <span
                className={`${
                  isSmallMobile ? "topTitleSpanForMobile" : "topTitleSpan"
                }`}
              >
                {!isSmallMobile
                  ? `${paper.name}`
                  : `${
                      paper.name.length < 10
                        ? paper.name
                        : paper.name.substring(0, 9) + "..."
                    }`}
              </span>
              {/* <span className="topTitleSpan">CBT Group : A</span> */}
              {/* <span className="topTitleSpan">निदर्शन</span> */}
              {/* <span className="topTitleSpan">Nidarshan</span> */}
              <div className="cbtExamTopTitleRightSide">
                <CountDown
                  timeInMinute={
                    paper.duration.hour * 60 + paper.duration.minute
                    // +
                    // Math.floor(paper.duration.second / 60)
                  }
                  timeInSecond={paper.duration.second}
                  isSmallMobile={isSmallMobile}
                  handleSubmit={handleSubmit}
                />
                {!isFullScreenDevice && (
                  <>
                    {!isShowSmallMobileRightQuestionNumbers ? (
                      <Icon
                        className="toggleRightSideQuestionNumbers"
                        icon="solar:hamburger-menu-bold"
                        onClick={() =>
                          setIsShowSmallMobileRightQuestionNumbers(() => {
                            return true;
                          })
                        }
                      />
                    ) : (
                      <Icon
                        className="toggleRightSideQuestionNumbers"
                        icon="maki:cross"
                        onClick={() =>
                          setIsShowSmallMobileRightQuestionNumbers(() => {
                            return false;
                          })
                        }
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="examQuestionsMainDiv"
            // style={{ height: "calc(100% - 80px)", width: "100%" }}
          >
            {isFullScreenDevice && (
              <div className="fullWidthWithQuestionNumbersMainDiv">
                <div className="leftSideQuestionsDetailsMainDiv">
                  <LeftQuestionsDetails
                    subjectsList={subjectsList}
                    // setsubjectsList={setsubjectsList}
                    currentQuestion={currentQuestion}
                    handleSaveAndNext={handleSaveAndNext}
                    currentQuestionNumber={currentQuestionNumber}
                    handleChangeSubject={handleChangeSubject}
                    handleAnswerCurrentQuestion={handleAnswerCurrentQuestion}
                    currentQuestionAnswer={currentQuestionAnswer}
                    // handleAnswerTheQuestion={handleAnswerTheQuestion}
                    answers={answers}
                    setAnswers={setAnswers}
                    handleClearResponse={handleClearResponse}
                    handleMarkForReview={handleMarkForReview}
                  />
                </div>
                <div className="rightSideQuestionNumberDetailsMainDiv">
                  <RightQuestionNumberDetails
                    currentSubject={subjectsList.arr[subjectsList.active]}
                    currentSubjectQuestionNumbers={
                      currentSubjectQuestionNumbers
                    }
                    handleChangeQuestionNumberOfSameSubject={
                      handleChangeQuestionNumberOfSameSubject
                    }
                    currentQuestionNumber={currentQuestionNumber}
                    answers={answers}
                    handleSubmit={handleSubmit}
                  />
                </div>
              </div>
            )}
            {!isFullScreenDevice && (
              <div className="fullWidthWithQuestionNumbersMainDiv">
                <div className="leftSideQuestionsDetailsMainDivForMobile">
                  <LeftQuestionsDetails
                    isSmallMobile={isSmallMobile}
                    subjectsList={subjectsList}
                    // setsubjectsList={setsubjectsList}
                    currentQuestion={currentQuestion}
                    handleSaveAndNext={handleSaveAndNext}
                    currentQuestionNumber={currentQuestionNumber}
                    handleChangeSubject={handleChangeSubject}
                    handleAnswerCurrentQuestion={handleAnswerCurrentQuestion}
                    currentQuestionAnswer={currentQuestionAnswer}
                    // handleAnswerTheQuestion={handleAnswerTheQuestion}
                    answers={answers}
                    setAnswers={setAnswers}
                    handleClearResponse={handleClearResponse}
                    handleMarkForReview={handleMarkForReview}
                  />
                </div>
              </div>
            )}
            {!isFullScreenDevice && isShowSmallMobileRightQuestionNumbers && (
              <div className="smallMobileRightQuestionNumbersToggled">
                <div className="rightSideQuestionNumberDetailsMainDivForSmallMobile">
                  <RightQuestionNumberDetails
                    isFullScreenDevice={isFullScreenDevice}
                    currentSubject={subjectsList.arr[subjectsList.active]}
                    currentSubjectQuestionNumbers={
                      currentSubjectQuestionNumbers
                    }
                    handleChangeQuestionNumberOfSameSubject={
                      handleChangeQuestionNumberOfSameSubject
                    }
                    currentQuestionNumber={currentQuestionNumber}
                    answers={answers}
                    handleSubmit={handleSubmit}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CbtPage;
