import React from "react";
import "./button.css";

export const SimpleSubmitButton = ({
  buttonSpan,
  width = "auto",
  height = "auto",
  onClickFunc = () => {},
  //   borderColor = "",
  //   backgroundColor = "#FFFC95",
  backgroundColor = "#F7F11D",
  boxShadow = true,
}) => {
  return (
    <button
      className="simpleWhitePushableButton"
      style={{
        width: `${width}`,
        height: `${height}`,
        // borderColor: `${borderColor}`,
        backgroundColor: `${backgroundColor}`,
        textTransform: "capitalize",

        boxShadow: `${
          boxShadow
            ? "6px 6px 12px #c5c5c586, -6px -6px 12px #ffffff88"
            : "none"
        }`,
      }}
      onClick={onClickFunc}
    >
      <span>{buttonSpan}</span>
    </button>
  );
};

export const CbtLeftBottomButtonComponent = ({
  buttonSpan,
  onClickFunc = () => {},
}) => {
  return (
    <button
      className="CbtLeftBottomButtonComponent"
      onClick={() => onClickFunc()}
    >
      <span>{buttonSpan}</span>
    </button>
  );
};
