import React, { useEffect, useState } from "react";
import "./singleTestSeries.css";
import { useLocation } from "react-router-dom";
import { allTestSeries } from "../../../constants/testSeries";
import SingleTestSeriesDetails from "./components/SingleTestSeriesDetails";
import SingleTestSeriesTests from "./components/SingleTestSeriesTests";
import SingleTestSeriesAnnouncements from "./components/SingleTestSeriesAnnouncements";
import SingleTestSeriesDownloadOmrs from "./components/SingleTestSeriesDownloadOmrs";
import { BasicDetailsSection } from "./components/BasicDetailsSection";
import { getFetch, postFetch } from "../../../functions/fetch/fetch.crud";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logIn, logOut } from "../../../redux/loggedUser/Slices";
import { makePaymentHandlerFunction } from "../../../functions/payment";

const SingleTestSeries = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const [loading, setLoading] = useState(true);
  const [effectInitiated, setEffectInitiated] = useState(false);
  const [currentTestSeries, setCurrentTestSeries] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [topSlider, setTopSlider] = useState(["Details", "Tests"]);
  const [sliderState, setSliderState] = useState(0);
  const [loggedUserState, setLoggedUserState] = useState(null);
  const [isTestSeriesPurchased, setIsTestSeriesPurchased] = useState(false);

  // const myTestSeries = allTestSeries.filter((el) => el.id === testSeriesId)[0];

  // const topSlider = ["Details", "Tests"];
  // const topSlider = ["Details", "Tests", "announcements"];

  // if (myTestSeries.theme === "omr") {
  //   topSlider.push("download omrs");
  // }

  const sliderObject = {
    0: () => (
      <SingleTestSeriesDetails
        myTestSeries={currentTestSeries}
        handlePurchaseTestSeries={handlePurchaseTestSeries}
        // loggedUser={loggedUserState}
        isTestSeriesPurchased={isTestSeriesPurchased}
      />
    ),
    1: () => (
      <SingleTestSeriesTests
        tests={currentTestSeries.tests}
        sections={currentTestSeries.sections}
        theme={currentTestSeries.theme}
        testSeriesId={currentTestSeries._id}
        loggedUserState={loggedUserState}
        // handlePurchaseTestSeries={handlePurchaseTestSeries}
        isTestSeriesPurchased={isTestSeriesPurchased}
      />
    ),
    2: () => <SingleTestSeriesDownloadOmrs />,
    // 2: () => <SingleTestSeriesAnnouncements />,
    // 3: () => <SingleTestSeriesDownloadOmrs />,
  };

  useEffect(() => {
    async function getCurrentTestSeries() {
      const searchParams = new URLSearchParams(location.search);
      const testSeriesId = searchParams.get("id");
      const response = await getFetch(`testSeries/dynamic/${testSeriesId}`);
      setCurrentTestSeries(response.testSeries);
      setLoggedUserState(loggedUser ? loggedUser : null);
      // console.log("testseries response = ", response);
      console.log("logged user =", loggedUser);
      setIsTestSeriesPurchased(
        loggedUser
          ? loggedUser.purchases?.some(
              (item) => item.offeringId === response.testSeries._id
            )
          : false
      );
      setLoading(false);
      setEffectInitiated(true);
      const dateData = response.testSeries.expiry ?? null;
      setExpiryDate(dateData ? new Date(dateData) : new Date());
      // const expiryDate = dateData ? new Date(dateData) : new Date();
      if (response.testSeries.theme === "OMR") {
        setTopSlider([...topSlider, "download omrs"]);
      }
      return;
    }
    if (loading && !effectInitiated) {
      getCurrentTestSeries();
    }
  }, [
    loading,
    effectInitiated,
    currentTestSeries,
    topSlider,
    location,
    loggedUser,
  ]);

  async function handlePurchaseTestSeries() {
    if (!loggedUserState) {
      toast.info("Login to purchase.");
      return;
    }
    let purchaseHistoryRequestData = {
      purchaseDate: new Date(),
      offeringId: currentTestSeries._id,
      offeringType: "TESTSERIES",
      isExpiryDuration: false, // update this for dynamic if i select for 90 days.
      // expiryDuration: "",   // update this for dynamic if i select for 90 days.
      // expiry: "",           // update this for dynamic if i select for 90 days.
      userId: loggedUserState._id,
      instituteId: currentTestSeries.instituteId,
      purchasePrice: currentTestSeries.nsp // update this for dynamic if i select for 90 days.
        ? currentTestSeries.nsp
        : currentTestSeries.mrp,
    };

    // makePaymentHandlerFunction;
    const razorpayPaymentResponse = await makePaymentHandlerFunction(
      {
        name: loggedUserState.name,
        email: loggedUserState.email,
        mobileNumber: loggedUserState.mobileNumber,
      },
      currentTestSeries.nsp ? currentTestSeries.nsp : currentTestSeries.mrp,
      loggedUserState._id,
      currentTestSeries._id,
      currentTestSeries.instituteId // we have to send inatitute name for payment.
      // institutePosterPath // we have to send institute poster.
    );

    if (!razorpayPaymentResponse) {
      console.log("error while razorpay payment.");
      return;
    }
    console.log("purchasing request data =", purchaseHistoryRequestData);
    const response = await postFetch(
      `purchasehistory/dynamic/create-order/${loggedUser._id}`,
      purchaseHistoryRequestData
    );
    if (response.error) {
      toast.error(response.error);
      console.log("error response while purchasing test series.", response);
      localStorage.removeItem("user");
      dispatch(logOut());
      return;
    } else {
      toast.success(response.message);
      console.log("test sereis purchase success response =", response);
      localStorage.setItem("token", response.token);
      setIsTestSeriesPurchased(
        response.user
          ? response.user.purchases?.some(
              (item) => item.offeringId === currentTestSeries._id
            )
          : false
      );
      localStorage.setItem("user", JSON.stringify(response.user));
      dispatch(logIn(response.user));

      return setTimeout(() => {}, 3000);
    }
  }

  // const sliderObject = {
  //   0: () => <SingleTestSeriesDetails myTestSeries={myTestSeries} />,
  //   1: () => (
  //     <SingleTestSeriesTests
  //       tests={myTestSeries.tests}
  //       sections={myTestSeries.sections}
  //       theme={myTestSeries.theme}
  //     />
  //   ),
  //   2: () => <SingleTestSeriesDownloadOmrs />,
  //   // 2: () => <SingleTestSeriesAnnouncements />,
  //   // 3: () => <SingleTestSeriesDownloadOmrs />,
  // };

  // const dateData = myTestSeries.expiry ?? null;
  // const expiryDate = dateData ? new Date(dateData) : new Date();
  let Component = sliderObject[sliderState];
  return (
    <>
      {!loading && (
        <>
          <div>
            <BasicDetailsSection
              currentTestSeries={currentTestSeries}
              // currentTestSeries={myTestSeries}
              expiryDate={expiryDate}
              handlePurchaseTestSeries={handlePurchaseTestSeries}
              // loggedUser={loggedUser}
              isTestSeriesPurchased={isTestSeriesPurchased}
            />
          </div>
          <div
            className="singleTestSeriesMainDiv"
            style={{
              margin: "10px",
              border: "1px solid rgba(1, 1, 1, 0.5)",
              borderRadius: "5px",
              position: "relative",
            }}
          >
            <div
              className="singleTestSeriesSlider"
              style={{ position: "sticky", top: "60px" }}
            >
              {topSlider.map((sliderel, slidereli) => {
                return (
                  <div
                    key={slidereli}
                    className={`singleTestSeriesSingleSlider ${
                      sliderState === slidereli
                        ? "activeSingleTestSeriesSingleSlider"
                        : ""
                    }`}
                    onClick={() => {
                      setSliderState(() => {
                        return slidereli;
                      });
                    }}
                  >
                    <span>{sliderel}</span>
                  </div>
                );
              })}
            </div>

            <Component myTestSeries={currentTestSeries} />
            {/* <Component myTestSeries={myTestSeries} /> */}
            {/* {sliderState === 0 && (
        <SingleTestSeriesDetails myTestSeries={myTestSeries} />
        // <SingleTestSeriesTests />
        // <SingleTestSeriesAnnouncements />
        // <SingleTestSeriesDownloadOmrs />
      )} */}
          </div>
        </>
      )}
    </>
  );
};

export default SingleTestSeries;
