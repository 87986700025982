import { configureStore } from "@reduxjs/toolkit";
import logInReducer from "./login/Slices";
import loggedUserReducer from "./loggedUser/Slices";

export const store = configureStore({
  reducer: {
    logIn: logInReducer,
    loggedUser: loggedUserReducer,
  },
});
