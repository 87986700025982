import "./loader.css";
import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "60px",
        minWidth: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "5px 0",
      }}
    >
      <p>
        <span className="loaderDesignSpan"></span>
        <span className="loaderLoadingSpan">Loading...</span>
      </p>
    </div>
  );
};

export default Loader;
