import React, { useEffect, useState } from "react";
import "./rightQuestionNumberDetails.css";
import {
  RightNumberIcons,
  WrongNumberIcons,
  AnsweredAndMarkedForReviewNumberIcons,
  MarkedForReviewNumberIcons,
  NotVisitedNumberIcons,
} from "../numberBoxes/NumberBoxes";
import { SimpleSubmitButton } from "../../../../components/common/buttons/Button";

const RightQuestionNumberDetails = ({
  isFullScreenDevice = false,
  currentSubject,
  currentSubjectQuestionNumbers,
  handleChangeQuestionNumberOfSameSubject,
  currentQuestionNumber,
  answers,
  handleSubmit,
}) => {
  const [previousQuestion, setPreviousQuestion] = useState(0);
  const [questionsCalculated, setQuestionsCalculated] = useState(false);
  const [subjectQuestions, setSubjectQuestions] = useState([]);
  const [answeredNumbers, setAnsweredNumbers] = useState({
    notVisited: 0,
    answered: 0,
    notAnswered: 0,
    markedForReview: 0,
    answeredAndMarkedForReview: 0,
  });

  useEffect(() => {
    // console.log("answers = ", JSON.stringify(answers));
    if (!questionsCalculated || previousQuestion !== currentQuestionNumber) {
      // console.log("right side is setting.");
      let notVisited = 0;
      let answered = 0;
      let notAnswered = 0;
      let markedForReview = 0;
      let answeredAndMarkedForReview = 0;
      const numberOfQuestions =
        currentSubjectQuestionNumbers[1] - currentSubjectQuestionNumbers[0];
      // console.log("curr arr = ", JSON.stringify(currentSubjectQuestionNumbers));
      let subjectQuestions = [];
      for (let i = 0; i < numberOfQuestions; i++) {
        subjectQuestions.push(`${i + 1}`);
        // console.log(i, " + 1 = ", i + 1 in answers);
        let currQuestionNumberForLoop =
          i + 1 + currentSubjectQuestionNumbers[0];
        currQuestionNumberForLoop in answers
          ? "answer" in answers[currQuestionNumberForLoop] &&
            answers[currQuestionNumberForLoop].answer[0] !== ""
            ? answers[currQuestionNumberForLoop].marked
              ? (answeredAndMarkedForReview += 1)
              : (answered += 1)
            : answers[currQuestionNumberForLoop].marked
            ? (markedForReview += 1)
            : (notAnswered += 1)
          : (notVisited += 1);
      }
      setAnsweredNumbers(() => {
        return {
          notVisited: notVisited,
          answered: answered,
          notAnswered: notAnswered,
          markedForReview: markedForReview,
          answeredAndMarkedForReview: answeredAndMarkedForReview,
        };
      });
      setSubjectQuestions(subjectQuestions);
      setQuestionsCalculated(true);
      setPreviousQuestion(currentQuestionNumber);
    }
    // if (previousQuestion !== currentQuestionNumber) {
    //   let notVisited = 0;
    //   let answered = 0;
    //   let notAnswered = 0;
    //   let markedForReview = 0;
    //   let answeredAndMarkedForReview = 0;
    //   const numberOfQuestions =
    //     currentSubjectQuestionNumbers[1] - currentSubjectQuestionNumbers[0];
    //   let subjectQuestions = [];
    //   for (let i = 0; i < numberOfQuestions; i++) {
    //     subjectQuestions.push(`${i + 1}`);
    //     let currQuestionNumberForLoop =
    //       i + 1 + currentSubjectQuestionNumbers[0];
    //     currQuestionNumberForLoop in answers
    //       ? "answer" in answers[currQuestionNumberForLoop] &&
    //         answers[currQuestionNumberForLoop].answer[0] !== ""
    //         ? answers[currQuestionNumberForLoop].marked
    //           ? (answeredAndMarkedForReview += 1)
    //           : (answered += 1)
    //         : answers[currQuestionNumberForLoop].marked
    //         ? (markedForReview += 1)
    //         : (notAnswered += 1)
    //       : (notVisited += 1);
    //   }
    //   setAnsweredNumbers(() => {
    //     return {
    //       notVisited: notVisited,
    //       answered: answered,
    //       notAnswered: notAnswered,
    //       markedForReview: markedForReview,
    //       answeredAndMarkedForReview: answeredAndMarkedForReview,
    //     };
    //   });
    //   setSubjectQuestions(subjectQuestions);
    //   setQuestionsCalculated(true);
    //   setPreviousQuestion(currentQuestionNumber);
    // }
  }, [
    answeredNumbers,
    answers,
    currentQuestionNumber,
    currentSubjectQuestionNumbers,
    previousQuestion,
    questionsCalculated,
  ]);

  return (
    <div
      className="rightSideQuestionNumberDetailsComponentMainDiv"
      style={{ backgroundColor: "white" }}
    >
      <div
        className="topSubjectName"
        style={{ textTransform: "capitalize", fontWeight: "600" }}
      >
        {currentSubject}
      </div>
      {/* <div className="topSubjectName">Gujarati Questions</div> */}
      <div className="topIconDescription">
        <div className="firstRow">
          <div className="wrongQuestionIcon">
            <WrongNumberIcons number={answeredNumbers.notAnswered} />
            <span className="rightSideTopIconSpan">Not Answered</span>
          </div>
          <div className="rightQuestionIcon">
            <RightNumberIcons number={answeredNumbers.answered} />
            <span className="rightSideTopIconSpan">Answered</span>
          </div>
        </div>
        <div className="secondRow">
          <div className="markedForReviewQuestionIcon">
            <MarkedForReviewNumberIcons
              number={answeredNumbers.markedForReview}
            />
            <span className="rightSideTopIconSpan">Marked For Review</span>
          </div>
          <div className="notVisitedQuestionIcon">
            <NotVisitedNumberIcons number={answeredNumbers.notVisited} />
            <span className="rightSideTopIconSpan">Not Visited</span>
          </div>
        </div>
        <div className="thirdRow">
          <div className="answeredAndMarkedForReviewQuestionIcon">
            <AnsweredAndMarkedForReviewNumberIcons
              number={answeredNumbers.answeredAndMarkedForReview}
            />
            <span className="rightSideTopIconSpan">
              Answered And Marked For Review
            </span>
          </div>
        </div>
      </div>
      <div className="answeredQuestionList">
        <div className="answeredQuestionListInnerDiv">
          {subjectQuestions.map((el, eli) => {
            // console.log("eli = ", eli);
            // console.log("el = ", el);
            let currentRightBoxQuestionNumber =
              eli + 1 + currentSubjectQuestionNumbers[0];
            return (
              <div key={eli} style={{ cursor: "pointer" }}>
                {currentRightBoxQuestionNumber in answers ? (
                  "answer" in answers[currentRightBoxQuestionNumber] &&
                  answers[currentRightBoxQuestionNumber].answer[0] !== "" ? (
                    answers[currentRightBoxQuestionNumber].marked ? (
                      <AnsweredAndMarkedForReviewNumberIcons
                        number={el}
                        onClickFunc={() =>
                          handleChangeQuestionNumberOfSameSubject(
                            eli + 1 + currentSubjectQuestionNumbers[0]
                          )
                        }
                      />
                    ) : (
                      <RightNumberIcons
                        number={el}
                        onClickFunc={() =>
                          handleChangeQuestionNumberOfSameSubject(
                            eli + 1 + currentSubjectQuestionNumbers[0]
                          )
                        }
                      />
                    )
                  ) : answers[currentRightBoxQuestionNumber].marked ? (
                    <MarkedForReviewNumberIcons
                      number={el}
                      onClickFunc={() =>
                        handleChangeQuestionNumberOfSameSubject(
                          eli + 1 + currentSubjectQuestionNumbers[0]
                        )
                      }
                    />
                  ) : (
                    <WrongNumberIcons
                      number={el}
                      onClickFunc={() =>
                        handleChangeQuestionNumberOfSameSubject(
                          eli + 1 + currentSubjectQuestionNumbers[0]
                        )
                      }
                    />
                  )
                ) : (
                  <NotVisitedNumberIcons
                    number={el}
                    onClickFunc={() =>
                      handleChangeQuestionNumberOfSameSubject(
                        eli + 1 + currentSubjectQuestionNumbers[0]
                      )
                    }
                  />
                )}

                {/* <AnsweredAndMarkedForReviewNumberIcons
                  number={el}
                  onClickFunc={() =>
                    handleChangeQuestionNumberOfSameSubject(
                      eli + 1 + currentSubjectQuestionNumbers[0]
                    )
                  }
                /> */}

                {/* <RightNumberIcons/> */}
                {/* <WrongNumberIcons/> */}
                {/* <MarkedForReviewNumberIcons/> */}
                {/* <AnsweredAndMarkedForReviewNumberIcons/> */}
                {/* <NotVisitedNumberIcons/> */}

                {/* <RightNumberIcons number={el} /> */}
                {/* <RightNumberIcons number={eli + 1} /> */}
              </div>
            );
          })}
        </div>
      </div>
      <div className="submitButtonMainDiv">
        <SimpleSubmitButton
          buttonSpan={"Submit"}
          width="90%"
          height="35px"
          onClickFunc={handleSubmit}
          // backgroundColor="#F7F11D"
          // borderColor="#00ff6a"
        />
      </div>
    </div>
  );
};

export default RightQuestionNumberDetails;
