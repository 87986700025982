import { Outlet, useLocation } from "react-router-dom";
// import Header from "../components/common/header/Header";
// import { useLocation } from "react-router-dom";
// import Home from "../pages/Home/Home";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logIn, logOut } from "../redux/loggedUser/Slices";
import { getFetch } from "../functions/fetch/fetch.crud";
// import axios from "axios";
// import { getAxios } from "../functions/axios/axios.crud.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GlobalLayout = () => {
  const location = useLocation();
  const [localUserFetched, setLocalUserFetched] = useState(false);
  const [tokenVerifyRequestInitiated, setTokenVerifyRequestInitiated] =
    useState(0);
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedUser) {
      if (
        !localUserFetched &&
        tokenVerifyRequestInitiated === 0 &&
        location.pathname !== "/login"
      ) {
        async function findLoggedInUser() {
          try {
            // console.log("in token verify use effect.");
            const loggedInUserInLocalStorage = localStorage.getItem("user");
            const token = localStorage.getItem("token");
            if (token) {
              // if (loggedInUserInLocalStorage && token) {
              //   console.log("sending token =", token);
              setTokenVerifyRequestInitiated(tokenVerifyRequestInitiated + 1);
              // const updatedToken = await axios.get(
              //   `${process.env.REACT_APP_API_URL}token/verifyandupdate`,
              //   {
              //     headers: { token },
              //   }
              // );
              const updatedToken = await getFetch("token/verifyandupdate");
              // const updatedToken = await getAxios("token/verifyandupdate");
              // console.log("updated token in global layout =", updatedToken);
              if (updatedToken.error) {
                toast.error(
                  updatedToken.error === "Token Expired."
                    ? updatedToken.error + " Please login again."
                    : updatedToken.error
                );
                // console.log("removing login.", updatedToken);
                // localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch(logOut());
                return;
              } else {
                localStorage.setItem("token", updatedToken.token);
                const loggedInUser = JSON.parse(loggedInUserInLocalStorage);
                dispatch(logIn(loggedInUser));
              }
            }
          } catch (error) {}
        }
        // console.log("getting details.");
        findLoggedInUser();
        setLocalUserFetched(true);
      }
    }
    //  else {
    //   console.log("user is created.", loggedUser);
    // }
  }, [
    dispatch,
    localUserFetched,
    location,
    loggedUser,
    tokenVerifyRequestInitiated,
  ]);

  return (
    <>
      {/* <Header /> */}
      <main>
        <ToastContainer position="top-center" autoClose={1500} />
        <Outlet /> {/* Placeholder for route content */}
      </main>
      {/* {location.pathname === "/" && <Home />} */}
    </>
  );
};

export default GlobalLayout;
