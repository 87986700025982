const generateQuestions = (testSeriesId, testId, subgroup, size) => {
  let opts = ["a", "b", "c", "d"];
  return new Array(size).fill("_").map((el, eli) => {
    const ans = opts[eli % 4];
    return {
      question: `${testSeriesId} - ${testId} what is ${subgroup} question ${
        eli + 1
      } આ ગુજરાતી છે.?`,
      options: [
        { optionName: "a", optionString: "Option A" },
        { optionName: "b", optionString: "Option B" },
        { optionName: "c", optionString: "Option C" },
        { optionName: "d", optionString: "Option D" },
      ],
      answer: [ans],
    };
    // return `${subgroup}-${eli + 1}`;
  });
};

const generateExamPaper = async (
  name,
  numberOfQuestions,
  groups,
  testSeriesId,
  testId,
  sectionName,
  isFree
) => {
  // const questions = [];
  let numberOfAttempts = 2;
  let questions = [];
  await groups.forEach(function (el) {
    questions.push(
      ...generateQuestions(testSeriesId, testId, el.name, el.numberOfQuestions)
    );
  });
  return {
    name,
    numberOfQuestions,
    questions,
    groups,
    offeringId: testSeriesId,
    testId,
    sectionName,
    isFree,
    numberOfAttempts,
  };
  //   console.log("questions = ;", JSON.stringify(questions));
};

const examPaperGeneraterFunction = (
  name,
  testSeriesId,
  testId,
  sectionName,
  isFree
) => {
  return generateExamPaper(
    name,
    100,
    [
      {
        name: "maths",
        numberOfQuestions: 40,
      },
      {
        name: "reasoning",
        numberOfQuestions: 30,
      },
      {
        name: "gujarati",
        numberOfQuestions: 15,
      },
      {
        name: "english",
        numberOfQuestions: 15,
      },
    ],
    testSeriesId,
    testId,
    sectionName,
    isFree
  );
};

export const generateAllExamPapers = async (testSeriesId, numberOfTests) => {
  let tests = [];
  let sections = [];
  let sectionName = "";
  for (let i = 0; i < numberOfTests; i++) {
    let isFree = false;
    if (i % 2 === 0) {
      sectionName = `section ${i + 1}`;
      sections.push(sectionName);
      isFree = true;
    }
    let name = `test ${i + 1}`;
    let testId = `testId ${i + 1}`;
    let thisTest = await examPaperGeneraterFunction(
      name,
      testSeriesId,
      testId,
      sectionName,
      isFree
    );
    tests.push(thisTest);
  }
  return { tests, sections };
};

// export const ExamPaper = {
//   name: "Dummy Test English",
//   numberOfQuestions: 100,
//   questions: [
//     ...generateQuestions("maths", 40),
//     ...generateQuestions(
//       "reasoning is the having maximum height and let's see what is the height after adding this to reasoning. yoyo honey singh.",
//       30
//     ),
//     ...generateQuestions("gujarati", 15),
//     ...generateQuestions("english", 15),
//   ],
//   groups: [
//     {
//       name: "maths",
//       numberOfQuestions: 40,
//     },
//     {
//       name: "reasoning",
//       numberOfQuestions: 30,
//     },
//     {
//       name: "gujarati",
//       numberOfQuestions: 15,
//     },
//     {
//       name: "english",
//       numberOfQuestions: 15,
//     },
//   ],
// };
