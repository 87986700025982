import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

const SingleTestSeriesTests = ({
  tests,
  sections,
  theme,
  testSeriesId,
  loggedUserState,
  isTestSeriesPurchased,
}) => {
  // const loggedUser = useSelector((state) => state.loggedUser.value);
  const [sectionId, setSectionId] = useState(0);
  // const [lastResultsArray, setLastResultsArray] = useState([]);
  // console.log("tests = ", tests);
  const navigate = useNavigate();

  const handleNavigateToResult = (resultProperty, id) => {
    // loggedUserState.givenTests.givenTestsArray.some(
    //   (givenTestSome) =>
    //     givenTestSome.offeringId === testSeriesId &&
    //     givenTestSome.givenOfferingTests.some((givenTestInnerSome) => {
    //       return givenTestInnerSome.testId === testEl._id;
    //     })
    // );
    const testSeriesTestsArray =
      loggedUserState.givenTests.givenTestsArray.find(
        (givenTestSome) => givenTestSome.offeringId === testSeriesId
      );
    const resultObject = testSeriesTestsArray.givenOfferingTests.find(
      (givenTestInnerSome) => givenTestInnerSome.testId === id
    );
    // resultProperty;
    if (resultProperty === "mainResult") {
      return navigate(`/result?id=${resultObject.resultId}&type=mainresult`);
    } else if (resultProperty === "lastResult") {
      return navigate(
        `/result?id=${resultObject.lastResultId}&type=lastresult`
      );
    }
    return;
    // navigate(`/result?id=${id}`);
  };

  const handleNavigateToPath = (test) => {
    // const handleNavigateToPath = (offering, test) => {
    // console.log("testSeriesId = ", testSeriesId);
    // console.log("tests = ", test);

    navigate(
      "/" +
        theme.toLowerCase() +
        "?test=" +
        test._id +
        "&series=" +
        testSeriesId
    );

    //   navigate(
    //     "/" +
    //       theme.toLowerCase() +
    //       "?offering=" +
    //       test.offeringId +
    //       // test.offering +
    //       "&test=" +
    //       test._id +
    //       "&series=" +
    //       testSeriesId
    //   );
  };
  console.log("tests in single test series =", tests);

  return (
    <div className="singleTestSeriesAllTestsMainDiv">
      {sections.map((sectionEl, sectionEli) => {
        return (
          <div key={sectionEli}>
            <div
              className="sectionNameInSingleTestSeriesAllTests"
              onClick={() => {
                setSectionId(() => {
                  return sectionEli;
                });
              }}
            >
              {sectionEl}
              <Icon
                icon="bxs:left-arrow"
                className={`${
                  sectionId === sectionEli
                    ? "dropDownIconActive-SingleTestSeriesAllTests"
                    : "dropDownIcon-SingleTestSeriesAllTests"
                }`}
              />
            </div>
            {sectionEli === sectionId && (
              <div className="allTestsUnderSingleSectionMainDiv-singleTestSeriesAllTests">
                {tests
                  .filter((el) => {
                    return el.sectionName === sectionEl;
                  })
                  .map((testEl, testEli) => {
                    // console.log("test el = ", testEl);
                    return (
                      <div
                        key={testEli}
                        className="singleTestMainDiv-singleTestSeriesAllTests"
                      >
                        <div className="singleTestTopDiv-singleTestSeriesAllTests">
                          <span className="testName-singleTestSeriesAllTests">
                            {testEl.name}
                          </span>
                          {/* for below button, purchase and login verification is remaining. */}
                          <div className="rightSideOfTestName-singleTestSeriesAllTests">
                            {loggedUserState &&
                              loggedUserState.givenTests.givenTestsArray.some(
                                (givenTestSome) =>
                                  givenTestSome.offeringId === testSeriesId &&
                                  givenTestSome.givenOfferingTests.some(
                                    (givenTestInnerSome) => {
                                      return (
                                        givenTestInnerSome.testId === testEl._id
                                      );
                                    }
                                  )
                              ) && (
                                <div className="simpleFlex">
                                  <button
                                    className="simpleBtn"
                                    onClick={() =>
                                      handleNavigateToResult(
                                        "mainResult",
                                        testEl._id
                                      )
                                    }
                                  >
                                    View Result
                                  </button>

                                  {loggedUserState.givenTests.givenTestsArray.some(
                                    (givenTestSome) =>
                                      givenTestSome.offeringId ===
                                        testSeriesId &&
                                      givenTestSome.givenOfferingTests.some(
                                        (givenTestInnerSome) =>
                                          givenTestInnerSome.testId ===
                                            testEl._id &&
                                          !!givenTestInnerSome.lastResultId
                                      )
                                  ) && (
                                    <button
                                      className="simpleBtn"
                                      onClick={() =>
                                        handleNavigateToResult(
                                          "lastResult",
                                          testEl._id
                                        )
                                      }
                                    >
                                      View Last Result
                                    </button>
                                  )}
                                </div>
                              )}
                            {loggedUserState ? (
                              isTestSeriesPurchased ? (
                                <div
                                  className="free-giveTestButton-singleTestSeriesAllTests"
                                  onClick={() => {
                                    handleNavigateToPath(testEl);
                                  }}
                                >
                                  Attempt Now
                                </div>
                              ) : (
                                <div
                                  className={`${
                                    testEl.isFree
                                      ? "free-giveTestButton-singleTestSeriesAllTests"
                                      : "giveTestButton-singleTestSeriesAllTests"
                                  }`}
                                  onClick={() => {
                                    if (testEl.isFree) {
                                      handleNavigateToPath(
                                        // testEl.offeringId,
                                        testEl
                                        // testEl._id
                                      );
                                    } else {
                                      toast.info(
                                        "Buy Test Series to Give Test."
                                      );
                                    }
                                  }}
                                >
                                  {testEl.isFree
                                    ? "Attempt For Free"
                                    : "Paid Test"}
                                </div>
                              )
                            ) : (
                              <button
                                className="simpleBtn"
                                onClick={() => navigate("/login")}
                              >
                                Login To View
                              </button>
                            )}
                            <span
                              style={{ fontSize: "10px", marginRight: "2px" }}
                            >
                              Remaining Attempts : 1/{testEl.numberOfAttempts}
                            </span>
                          </div>
                        </div>
                        <div className="singleTestSecondDiv-singleTestSeriesAllTests">
                          Questions : {testEl.numberOfQuestions}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SingleTestSeriesTests;
