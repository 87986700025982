import React, { useEffect, useState } from "react";
import "./allTestSeries.css";
// import { allTestSeries } from "../../../constants/testSeries";
import TestSeriesCard from "../../../cards/TestSeriesCard/TestSeriesCard";
import { getFetch } from "../../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";
import Loader from "../../../components/common/loader/Loader";

const AllTestSeries = () => {
  const [allTestSeries, setAllTestSeries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getTestSeriesData() {
      const response = await getFetch("testseries");
      if (response.error) {
        console.log("error response =", response);
        toast.error(response.error);
        return;
      } else {
        console.log("success response =", response);
        setAllTestSeries(response.testseries);
        setLoading(() => {
          return false;
        });
        return;
      }
    }

    if (loading) {
      getTestSeriesData();
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="allTestSeriesMainDiv">
            <div className="allTestSeriesTopTitle">
              <span>All Test Series</span>
            </div>
            <div className="AllTestSeriesCardsInAllTestSeriesDiv">
              {allTestSeries.map((el, eli) => {
                return <TestSeriesCard key={eli} element={el} />;
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AllTestSeries;
