import React from "react";
import "./cbtQuestionComponent.css";

const CbtQuestionComponent = ({
  currentQuestion,
  currentQuestionNumber,
  handleAnswerCurrentQuestion,
  currentQuestionAnswer,
  // handleAnswerTheQuestion,
  // answers,
}) => {
  // const [currentQuestionAnswer, setcurrentQuestionAnswer] = useState(
  //   "currentQuestionNumber" in answers ? answers[currentQuestionNumber] : ""
  // );

  // const handleChangeCurrentQuestionAnswer = (ans) => {
  //   setcurrentQuestionAnswer(ans);
  // };

  return (
    <div className="cbtSingleQuestionMainDiv">
      <div className="cbtQuestion">
        {/* <span className="cbtQuestionNumber">1.</span>{" "} */}
        {/* <span className="cbtQuestionSpan">{currentQuestion.question}</span> */}
        <span className="cbtQuestionSpan">
          {currentQuestion.questionType === "RICH_TEXT" ? (
            <div
              className="showRichText"
              dangerouslySetInnerHTML={{
                __html: currentQuestion.question,
              }}
            />
          ) : (
            <div className="omrQuestionDiv"> {currentQuestion.question}</div>
          )}
        </span>
        {/* <span className="cbtQuestionSpan">What is question 1?</span> */}
      </div>
      <div className="cbtQuestionOptions">
        {currentQuestion.options.map((el, eli) => {
          return (
            <div key={eli} className="cbtQuestionSingleOption">
              <input
                type="radio"
                name={`que-${currentQuestionNumber}`}
                id={`que-${currentQuestionNumber}-${el.optionName}`}
                checked={currentQuestionAnswer === el.optionName}
                // checked={
                //   answers[currentQuestionNumber]
                //     ? answers[currentQuestionNumber][0] === el.optionName
                //     : false
                // }
                // onChange={() =>
                //   handleChangeCurrentQuestionAnswer(el.optionName)
                // }
                onChange={
                  () => handleAnswerCurrentQuestion(el.optionName)
                  // handleAnswerTheQuestion(currentQuestionNumber, el.optionName)
                }
              />
              <label
                htmlFor={`que-${currentQuestionNumber}-${el.optionName}`}
                className="cbtQuestionSingleOptionLabel"
              >
                <span className="cbtQuestionSingleOptionSpan">
                  {/* {el.optionString} */}
                  {el.optionType === "RICH_TEXT" ? (
                    <div
                      className="showRichText"
                      dangerouslySetInnerHTML={{
                        __html: el.optionString,
                      }}
                    />
                  ) : (
                    <> {el.optionString}</>
                  )}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CbtQuestionComponent;
