import React, { useEffect, useState } from "react";
import CbtPage from "./CbtPage";
// import { cbtExamPaper } from "./components/cbtConstants/cbtPaper";
import { useLocation, useNavigate } from "react-router-dom";
// import { allTestSeries } from "../../constants/testSeries";
import { getFetch } from "../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CbtMainPage = () => {
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [paper, setPaper] = useState(null);
  const [startTest, setStartTest] = useState(false);
  const [loggedUserState, setLoggedUserState] = useState(null);
  const [isOfferingPurchased, setIsOfferingPurchased] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    //  const offering_id = searchParams.get("offering");
    // const test_id = searchParams.get("test");
    const test_id = searchParams.get("test");
    const test_series_id = searchParams.get("series");

    async function getPaper() {
      // const response = await getFetch(`test/dynamic/${test_id}`);
      const response = await getFetch(
        `test/dynamic/${test_id}/${test_series_id}`
      );
      if (response.error) {
        if (response.error === "Invalid Token") {
          toast.error("Please Login Again To Give Test.");
          return setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          toast.error(response.error);
          console.log("error while getting test =", response);
          return;
        }
      } else {
        console.log("full paper response =", response);
        console.log("paper =", response.test);
        console.log("Logged user  =", loggedUser);
        setPaper(response.test);
        setLoggedUserState(loggedUser ? loggedUser : null);
        setIsOfferingPurchased(
          loggedUser
            ? loggedUser.purchases?.some(
                (item) => item.offeringId === response.test.offeringId
              )
            : false
        );
        setLoading(false);
        return;
      }
    }

    if (loading) {
      getPaper();
    }
  }, [location, loading, paper, navigate, loggedUser]);

  // const paper = allTestSeries
  //   .filter((testSeriesEl) => {
  //     return testSeriesEl.id === offering_id;
  //   })[0]
  //   .tests.filter((testEl) => {
  //     return testEl.testId === test_id;
  //   })[0];

  // const paper = cbtExamPaper;

  // full screen functions.
  function handleEnterFullScreen() {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
        console.error("Failed to enter full screen:");
        console.log("Error = ", err);
      });
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  function handleExitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch((err) => {
        console.error("Failed to exit full screen:", err);
      });
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  function handleToggleFullScreen(process = "toggle") {
    const isFullScreen =
      window.outerHeight + 14 === window.innerHeight &&
      window.outerWidth + 14 === window.innerWidth;
    // const isFullScreen =
    //   document.fullscreenElement ||
    //   document.mozFullScreenElement ||
    //   document.webkitFullscreenElement ||
    //   document.msFullscreenElement;

    // console.log("isFullScreen = ", isFullScreen);
    // console.log(
    //   "inner width and height = ",
    //   window.innerWidth,
    //   " and ",
    //   window.innerHeight
    // );
    // console.log(
    //   "outer width and height = ",
    //   window.outerWidth,
    //   " and ",
    //   window.outerHeight
    // );
    if (
      window.outerHeight + 14 === window.innerHeight &&
      window.outerWidth + 14 === window.innerWidth
    )
      //   alert("you are in full screen.");
      console.log("you are in full screen.");

    if (process === "enter") {
      handleEnterFullScreen();
    } else if (process === "exit") {
      handleExitFullScreen();
    } else if (process === "toggle") {
      if (!isFullScreen) {
        // Enter full screen modessssssssss
        handleEnterFullScreen();
      } else {
        // Exit full screen mode
        handleExitFullScreen();
      }
    }
  }

  function handleStartTest() {
    function enterTest() {
      handleToggleFullScreen("enter");
      setStartTest(() => {
        return true;
      });
      return;
    }
    if (loggedUserState) {
      // handleToggleFullScreen("enter");
      // setStartTest(() => {
      //   return true;
      // });
      // return;
      if (isOfferingPurchased) {
        enterTest();
        return;
      } else {
        if (
          window.confirm(
            "You have not purchased test. Your answers will not saved. Are You sure you want to give the test?"
          )
        ) {
          enterTest();
          return;
        }
      }
    } else {
      toast.error("Login to give test.");
      setStartTest(() => {
        return false;
      });
      return setTimeout(() => {
        navigate("/login");
      }, 2500);
    }
  }
  return (
    <>
      {!startTest ? (
        <button onClick={handleStartTest}>Enter Test.</button>
      ) : (
        <CbtPage
          paper={paper}
          isOfferingPurchased={isOfferingPurchased}
          exitFullScreen={handleExitFullScreen}
          loggedUserState={loggedUserState}
          setLoggedUserState={setLoggedUserState}
        />
      )}
    </>
  );
};

export default CbtMainPage;
