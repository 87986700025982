import React, { useEffect, useState } from "react";
import OmrPage from "./OmrPage";
import "./omrPage.css";
// import { cbtExamPaper } from "../CbtPage/components/cbtConstants/cbtPaper";
// import { allTestSeries } from "../../constants/testSeries";
import { useLocation, useNavigate } from "react-router-dom";
import { getFetch } from "../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const OmrMainPage = () => {
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const navigate = useNavigate();
  const [isEnteredTest, setIsEnteredTest] = useState(false);
  // const paper = cbtExamPaper;
  const [loading, setLoading] = useState(true);
  const [paper, setPaper] = useState(null);
  const [loggedUserState, setLoggedUserState] = useState(null);
  const [isOfferingPurchased, setIsOfferingPurchased] = useState(false);
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);

  // const offering_id = searchParams.get("offering");
  // const test_id = searchParams.get("test");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // const offering_id = searchParams.get("offering");
    const test_id = searchParams.get("test");
    const test_series_id = searchParams.get("series");

    async function getPaper() {
      const response = await getFetch(
        `test/dynamic/${test_id}/${test_series_id}`
      );
      console.log("response =", response);
      // setPaper(response);
      // setLoading(false);
      // return;

      if (response.error) {
        if (response.error === "Invalid Token") {
          toast.error("Please Login Again To Give Test.");
          return setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          toast.error(response.error);
          console.log("error while getting test =", response);
          return;
        }
      } else {
        console.log("full paper response =", response);
        console.log("paper =", response.test);
        console.log("loggedUser =", loggedUser);
        setPaper(response.test);
        setLoggedUserState(loggedUser ? loggedUser : null);
        setIsOfferingPurchased(
          loggedUser
            ? loggedUser.purchases?.some(
                (item) => item.offeringId === response.test.offeringId
              )
            : false
        );
        setLoading(false);
        return;
      }
    }

    if (loading) {
      getPaper();
    }
  }, [loading, paper, location, navigate, loggedUser]);

  // const paper = allTestSeries
  //   .filter((testSeriesEl) => {
  //     return testSeriesEl.id === offering_id;
  //   })[0]
  //   .tests.filter((testEl) => {
  //     return testEl.testId === test_id;
  //   })[0];

  function handleEnterFullScreen() {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
        console.error("Failed to enter full screen:");
        console.log("Error = ", err);
      });
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
    return;
  }

  function handleExitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch((err) => {
        console.error("Failed to exit full screen:", err);
      });
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    return;
  }

  function handleEnterTheTest() {
    function enterTest() {
      handleEnterFullScreen();
      setIsEnteredTest(() => {
        return true;
      });
      return;
    }

    if (loggedUserState) {
      // handleEnterFullScreen();
      // setIsEnteredTest(() => {
      //   return true;
      // });
      // return;
      if (isOfferingPurchased) {
        enterTest();
        return;
      } else {
        if (
          window.confirm(
            "You have not purchased test. Your answers will not saved. Are You sure you want to give the test?"
          )
        ) {
          enterTest();
          return;
        }
      }
    } else {
      toast.error("Login to give test.");
      setIsEnteredTest(() => {
        return false;
      });
      return setTimeout(() => {
        navigate("/login");
      }, 2500);
    }
  }
  return (
    <div
    // style={{
    //   height: "100vh",
    //   width: "100vw",
    // }}
    >
      {isEnteredTest ? (
        <OmrPage
          paper={paper}
          isOfferingPurchased={isOfferingPurchased}
          exitFullScreen={handleExitFullScreen}
          loggedUserState={loggedUserState}
          setLoggedUserState={setLoggedUserState}
        />
      ) : (
        <div className="enterTheOmrTestMainDiv">
          <button
            className="enterTheOmrTestButton"
            onClick={handleEnterTheTest}
          >
            Enter The Test
          </button>
        </div>
      )}
    </div>
  );
};

export default OmrMainPage;
