// export const navMainUl = [
//   {
//     name: "Home",
//     nameWidth: 42,
//     link: "/",
//     // icon: "HouseDoorFill",
//     icon: "ic:round-home",
//   },
//   {
//     name: "Offerings",
//     nameWidth: 87,
//     link: "/",
//     // icon: "Database",
//     icon: "fluent-mdl2:product-variant",
//     childDropdown: [
//       { name: "Test Series", link: "/", icon: "Clipboard2CheckFill" },
//       { name: "Mock Test Series", link: "/", icon: "Clipboard2DataFill" },
//     ],
//   },
//   {
//     name: "My Profile",
//     nameWidth: 97,
//     link: "/",
//     // icon: "PersonCircle",
//     icon: "iconamoon:profile-fill",
//     childDropdown: [
//       { name: "Dashboard", link: "/", icon: "Easel2Fill" },
//       { name: "My Purchases", link: "/", icon: "BagFill" },
//     ],
//   },
// ];

export const navMainUl = [
  {
    name: "Home",
    nameWidth: 50,
    link: "/",
    icon: "ic:sharp-home",
    // icon: "HouseDoorFill", // this is of bootstrap.
    // icon: "ic:round-home",
  },
  // {
  //   name: "Omr",
  //   nameWidth: 42,
  //   link: "/omr",
  //   icon: "heroicons:computer-desktop-solid",
  //   // icon: "HouseDoorFill", // this is of bootstrap.
  //   // icon: "ic:round-home",
  // },
  // {
  //   name: "cbt",
  //   nameWidth: 42,
  //   link: "/cbt",
  //   icon: "heroicons:computer-desktop-solid",
  //   // icon: "HouseDoorFill", // this is of bootstrap.
  //   // icon: "ic:round-home",
  // },
  // {
  //   name: "popup",
  //   nameWidth: 55,
  //   link: "/popup",
  //   icon: "heroicons:computer-desktop-solid",
  //   // icon: "HouseDoorFill", // this is of bootstrap.
  //   // icon: "ic:round-home",
  // },
  {
    name: "Offerings",
    nameWidth: 95,
    // link: "/",
    // icon: "Database",
    icon: "fluent-mdl2:product-variant",
    childDropdown: [
      {
        name: "Test Series",
        link: "/alltestseries",
        icon: "healthicons:i-exam-multiple-choice",
      },
      {
        name: "Mock Test Series",
        link: "/",
        icon: "healthicons:i-exam-qualification",
      },
    ],
  },
  {
    name: "My Profile",
    nameWidth: 110,
    // link: "/",
    // icon: "PersonCircle",
    icon: "iconamoon:profile-fill",
    childDropdown: [
      { name: "Dashboard", link: "/", icon: "mage:dashboard-bar-fill" },
      { name: "My Purchases", link: "/", icon: "solar:cart-3-bold" },
    ],
  },
];
