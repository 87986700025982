import React from "react";
import "./createOmrPage.css";
// import nidDarkFullName from "../../../../logoImg/nidDarkFullName.png";
// import {
//   arrOfLength_10,
//   createOmrPageConstants,
//   rollNumberArr,
//   testSeriesArr,
// } from "./constants/createOmrPageConstants";
import OmrComponent from "./OmrComponent";

const CreateOmrPage = ({ answers, handleChangeAnswer, numberOfQuestions }) => {
  // let withOptionE = false;
  let withOptionE = true;
  let omrs = [50, 100, 200, 250, 300];
  // let numOfQuestions = 300;
  // let numOfQuestions = 250;
  // let numOfQuestions = 200;
  // let numOfQuestions = 100;
  // let numOfQuestions = 50;
  let numberOfQuestionsInOmr = omrs.find(
    (element) => element > numberOfQuestions
  );
  // console.log("omr questions =", totalQuestions);
  return (
    <div className="createOmrFullDiv">
      <div className="omrFullSizeOuterDiv">
        <OmrComponent
          handleChangeAnswer={handleChangeAnswer}
          answers={answers}
          withOptionE={withOptionE}
          numberOfQuestionsInOmr={numberOfQuestionsInOmr}
          totalQuestions={numberOfQuestions}
        />
      </div>
    </div>
  );
};

export default CreateOmrPage;
