import React, { useEffect, useRef } from "react";
import "./omrQuestionComponent.css";

const OmrQuestionComponent = ({
  currentQuestion,
  currentQuestionNumber,
  // setQuestionsHeight,
  handleChangeAnswer,
  answers,
  // handleUpdateQuestionHeight,
  // handleAnswerCurrentQuestion,
  // currentQuestionAnswer,
}) => {
  const currQuestionComponentRef = useRef(null);
  useEffect(() => {
    if (currQuestionComponentRef.current) {
      // setQuestionsHeight((prevValue) => {
      //   let updatedVal = {
      //     ...prevValue,
      //     [currentQuestionNumber]:
      //       currQuestionComponentRef.current.clientHeight,
      //   };
      //   // console.log("updated val = ", updatedVal);
      //   return updatedVal;
      // });
    } else {
      console.log("height is not set.");
    }
  }, [currentQuestionNumber]);
  // }, [currentQuestionNumber, setQuestionsHeight]);
  return (
    <div className="omrSingleQuestionMainDiv" ref={currQuestionComponentRef}>
      <div className="omrQuestion">
        {/* <div className="omrQuestion simpleFlex"> */}
        <div className="omrQuestionSpan" style={{ width: "100%" }}>
          {/* {currentQuestionNumber}. {currentQuestion.question} */}
          <span style={{ maxWidth: "45px" }}>{currentQuestionNumber}.</span>
          {/* <span style={{ width: "45px" }}>{currentQuestionNumber}.</span> */}
          {currentQuestion.questionType === "RICH_TEXT" ? (
            <div
              className="showRichText omrQuestionDiv"
              // style={{ width: "calc(100% - 55px)" }}
              dangerouslySetInnerHTML={{
                __html: currentQuestion.question,
              }}
            />
          ) : (
            <div className="omrQuestionDiv"> {currentQuestion.question}</div>
          )}
        </div>
      </div>
      <div className="omrQuestionOptions">
        {currentQuestion.options.map((el, eli) => {
          return (
            <div key={eli} className="omrQuestionSingleOption">
              <input
                type="radio"
                name={`que-${currentQuestionNumber}`}
                id={`que-${currentQuestionNumber}-${el.optionName}`}
                checked={answers[currentQuestionNumber]?.answer?.includes(
                  el.optionName
                )}
                onChange={() =>
                  handleChangeAnswer(currentQuestionNumber, el.optionName)
                }
                // onChange={() => {
                //   // console.log(
                //   //   "passing conditions = ",
                //   //   currentQuestionNumber,
                //   //   el.optionName
                //   // );
                //   handleChangeAnswer(currentQuestionNumber, el.optionName);
                // }}
              />
              <label
                htmlFor={`que-${currentQuestionNumber}-${el.optionName}`}
                className="omrQuestionSingleOptionLabel"
              >
                <span className="omrQuestionSingleOptionSpan">
                  {el.optionType === "RICH_TEXT" ? (
                    <div
                      className="showRichText"
                      dangerouslySetInnerHTML={{
                        __html: el.optionString,
                      }}
                    />
                  ) : (
                    <> {el.optionString}</>
                  )}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OmrQuestionComponent;
