import React, { useEffect } from "react";
import nidDarkFullName from "../../../../logoImg/nidDarkFullName.png";
import {
  arrOfLength_10,
  createOmrPageConstants,
  rollNumberArr,
  testSeriesArr,
  // } from "./constants/createOmrPageConstants";
} from "./constants/createOmrPageConstants";
import { toast } from "react-toastify";

const OmrComponent = ({
  withOptionE,
  numberOfQuestionsInOmr,
  answers,
  handleChangeAnswer,
  totalQuestions,
}) => {
  useEffect(() => {}, [answers]);
  return (
    <div className="omrFullSizeDiv">
      {/* now in omr */}
      <div className="omrTopRowWithCircles">
        <div className="cornerCircle">
          {/* remove if corner circle inner point detection is off. */}
          <div className="cornerCircleInnerFirstCircle">
            <div className="cornerCircleInnerSecondCircle"></div>
          </div>
        </div>
        <div className="omrTopRightCornerDiv">
          <div className="circleForOrientationDetection"></div>
          <div className="cornerCircle">
            {/* remove if corner circle inner point detection is off. */}
            <div className="cornerCircleInnerFirstCircle">
              <div className="cornerCircleInnerSecondCircle"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="omrDetailsFullDiv">
        {/* omr top row */}
        <div
          className="omrDetailsTopRow"
          style={{
            "--roll-number-circle-width":
              createOmrPageConstants[numberOfQuestionsInOmr]
                .rollNumberCircleWidth,
            "--test-series-circle-width":
              createOmrPageConstants[numberOfQuestionsInOmr]
                .testSeriesCircleWidth,
          }}
        >
          {/* first element */}
          <div className="omrDetailsTopRowFirstElementMainDiv">
            <div className="omrDetailsTopRowFirstElementInnerFirstDiv">
              <p className="omrDetailsTopRowFirstElementTopSpan">Roll Number</p>
              <div className="enterRollNumberDiv">
                {rollNumberArr.map((numel, numeli) => (
                  // {Array.from({ length: 10 }).map((_, i) => (
                  <div
                    key={numeli}
                    className="enterRollNumberSingleDigitDiv"
                  ></div>
                ))}
                {/* <div className="enterRollNumberSingleDigitDiv"></div> */}
                {/* <input type="number" name="roll1" id="roll1" /> */}
              </div>
            </div>
            <div className="omrDetailsTopRowFirstElementCheckCirclesMainDiv">
              {rollNumberArr.map((el, eli) => (
                // {Array.from({ length: 10 }).map((el, eli) => (
                <div
                  key={eli}
                  className={`${
                    eli % 2 === 0
                      ? "omrCheckForRollNumberOddColumn"
                      : "omrCheckForRollNumberEvenColumn"
                  } omrDetailsTopRowFirstElementCheckCirclesSingleColumnDiv`}
                >
                  {arrOfLength_10.map((nestel, nesteli) => (
                    // {Array.from({ length: 10 }).map((nestel, nesteli) => (
                    <div key={nesteli} className="rollNumberCheckCircle">
                      <span>{nesteli}</span>
                    </div>
                  ))}
                </div>
              ))}
              {/* <div className="omrDetailsTopRowFirstElementCheckCirclesSingleColumnDiv"></div> */}
            </div>
          </div>
          {/* second element */}
          <div className="omrDetailsTopRowSecondElementMainDiv">
            <div className="omrDetailsTopRowSecondElementInnerFirstDiv">
              <p className="omrDetailsTopRowSecondElementTopSpan">
                Test Booklet Series
              </p>
            </div>
            <div className="omrDetailsTopRowSecondElementInnerSecondDiv">
              <div className="omrTestBookletSeriesInputTopBox"></div>
              <div className="omrTestBookletSeriesInputCircleMainDiv">
                {testSeriesArr.map((el, eli) => {
                  // {Array.from({ length: 8 }).map((el, eli) => {
                  let row = Math.floor(eli / 2) + 1;
                  let evenRow = row % 2 === 0;
                  let seriesArr = ["A", "B", "C", "D", "E", "F", "G", "H"];
                  return (
                    <div
                      key={eli}
                      className={`${
                        evenRow
                          ? "testSeriesCheckCircleParentDivEvenRow"
                          : "testSeriesCheckCircleParentDivOddRow"
                      } testSeriesCheckCircleParentDiv`}
                    >
                      <div className="testSeriesCheckCircle">
                        <span>{seriesArr[eli]}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* omr options box */}
        <div
          className="optionContainerMainDiv"
          style={{
            // "--option-with-number-width": "65px",
            // "--num-width": "12px",
            "--option-with-number-width":
              createOmrPageConstants[numberOfQuestionsInOmr].eachColumnWidth,
            "--num-width":
              createOmrPageConstants[numberOfQuestionsInOmr]
                .numberInColumnWidth,
            "--options-width":
              "calc(var(--option-with-number-width) - var(--num-width))",
            "--top-column-height":
              createOmrPageConstants[numberOfQuestionsInOmr].topColumnHeight,
            "--options-circle-width":
              createOmrPageConstants[numberOfQuestionsInOmr].optionsCircleWidth,
            "--options-row-height":
              createOmrPageConstants[numberOfQuestionsInOmr].optionRowHeight,
            "--options-row-number-font-size":
              createOmrPageConstants[numberOfQuestionsInOmr]
                .optionRowNumberFontSize,
            "--options-row-options-font-size":
              createOmrPageConstants[numberOfQuestionsInOmr]
                .optionRowOptionsFontSize,

            // "--options-width": "calc(65px - 12px)",
            // backgroundImage: `url(${nidDarkFullName})`,
            // backgroundSize: "contain",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "center",
            // width: "350px",
            // height: "100vh",
          }}
        >
          {createOmrPageConstants[numberOfQuestionsInOmr].columnArr.map(
            (colel, coleli) => {
              // {Array.from({ length: 6 }).map((colel, coleli) => {
              return (
                <div
                  key={coleli}
                  className="optionContainerSingleColumnMainDiv"
                >
                  <div className="optionContainerColumnTopRow">
                    <div className="singleOptionRowNumberInSingleColumnTopRow"></div>
                    <div className="optionsListInSingleColumnTopRow">
                      {withOptionE
                        ? createOmrPageConstants[
                            numberOfQuestionsInOmr
                          ].optionArrWithOptionE.map((el2, eli2) => {
                            // {Array.from({ length: 5 }).map((el2, eli2) => {
                            let options = ["A", "B", "C", "D", "E"];
                            return (
                              <div
                                className="singleOptionInSingleColumnTopRow"
                                key={eli2}
                              >
                                <span>{options[eli2]}</span>
                              </div>
                            );
                          })
                        : createOmrPageConstants[
                            numberOfQuestionsInOmr
                          ].optionArrWithOptionE.map((el2, eli2) => {
                            // {Array.from({ length: 5 }).map((el2, eli2) => {
                            let options = ["A", "B", "C", "D", "E"];
                            return (
                              <div
                                className="singleOptionInSingleColumnTopRow"
                                key={eli2}
                              >
                                <span>{options[eli2]}</span>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                  {createOmrPageConstants[
                    numberOfQuestionsInOmr
                  ].rowInEachColumnArr.map((el, eli) => {
                    // {Array.from({ length: 50 }).map((el, eli) => {
                    let showBackGround =
                      (Math.floor(eli / 5) + coleli) % 2 === 0;
                    let questionNumber =
                      coleli *
                        createOmrPageConstants[numberOfQuestionsInOmr]
                          .rowInEachColumn +
                      eli +
                      1;
                    return (
                      <div className="singleOptionRow" key={eli}>
                        <div className="singleOptionRowNumber">
                          {questionNumber}
                        </div>
                        <div
                          className={`${
                            showBackGround
                              ? "optionSingleRowOptionsBackGround"
                              : ""
                          } optionSingleRowOptions`}
                        >
                          {withOptionE
                            ? createOmrPageConstants[
                                numberOfQuestionsInOmr
                              ].optionArrWithOptionE.map((el2, eli2) => {
                                // {Array.from({ length: 5 }).map((el2, eli2) => {
                                let options = ["a", "b", "c", "d", "e"];
                                return (
                                  <div
                                    className={`singleOption ${
                                      answers[questionNumber]?.answer?.includes(
                                        options[eli2]
                                      )
                                        ? "isSelected"
                                        : "isNotSelected"
                                    }`}
                                    key={eli2}
                                    onClick={() => {
                                      if (options[eli2] === "e") {
                                        if (questionNumber > totalQuestions) {
                                          // console.log("out of range.");
                                          toast.error(
                                            `Total number of questions in paper : ${totalQuestions}`
                                          );
                                          return;
                                        }
                                        return handleChangeAnswer(
                                          questionNumber,
                                          options[eli2],
                                          // true
                                          options[eli2] === "e"
                                        );
                                      } else {
                                        toast.info(
                                          "Only option E can be selected."
                                        );
                                      }
                                    }}
                                  >
                                    <span>{options[eli2]}</span>
                                  </div>
                                );
                              })
                            : createOmrPageConstants[
                                numberOfQuestionsInOmr
                              ].optionArrWithoutOptionE.map((el2, eli2) => {
                                // {Array.from({ length: 5 }).map((el2, eli2) => {
                                let options = ["A", "B", "C", "D", "E"];
                                return (
                                  <div
                                    className="singleOption isNotSelected"
                                    key={eli2}
                                  >
                                    <span>{options[eli2]}</span>
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
          )}
        </div>
      </div>

      <div className="omrBottomRowWithCircles">
        <div className="cornerCircle">
          {/* remove if corner circle inner point detection is off. */}
          <div className="cornerCircleInnerFirstCircle">
            <div className="cornerCircleInnerSecondCircle"></div>
          </div>
        </div>
        <div className="cornerCircle">
          {/* remove if corner circle inner point detection is off. */}
          <div className="cornerCircleInnerFirstCircle">
            <div className="cornerCircleInnerSecondCircle"></div>
          </div>
        </div>
      </div>
      {/* image for background */}
      <div className="backgroundImageDiv">
        <img src={nidDarkFullName} alt="imag-is" className="backgroundImage" />
      </div>
    </div>
  );
};

export default OmrComponent;
