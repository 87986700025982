import React, { useState } from "react";
import "./mobileWidthHeader.css";
import { Icon } from "@iconify/react";
import { navMainUl } from "../../../../constants/header";

const MobileWidthHeader = ({ handleNavigate, userName }) => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showMobileDropDown, setshowMobileDropDown] = useState("");

  const handleShowNavMenu = (conditionBool) => {
    setShowNavMenu(conditionBool);
    if (!conditionBool) {
      setshowMobileDropDown("");
    }
  };
  const handleShowDropDown = (name) => {
    if (showMobileDropDown === name) {
      setshowMobileDropDown("");
    } else setshowMobileDropDown(name);
  };
  return (
    <>
      <div className="mobileHeaderMainDiv">
        <span className="mobileNameHeader" onClick={() => handleNavigate("/")}>
          निदर्शन
        </span>
        {/* <span className="mobileNameHeader">Nidarshan</span> */}
        {!showNavMenu ? (
          <Icon
            className="mobileShowMenuIcon"
            icon="solar:hamburger-menu-bold"
            onClick={() => handleShowNavMenu(true)}
          />
        ) : (
          <Icon
            className="mobileHideMenuIcon"
            icon="maki:cross"
            onClick={() => handleShowNavMenu(false)}
          />
        )}
      </div>
      {showNavMenu && (
        <div
          className="mobileNavBarMainDiv"
          style={{ height: "calc(100vh - 60px)" }}
        >
          <div
            className="mobileNavBarBoxDiv"
            style={{ textTransform: "capitalize" }}
          >
            {navMainUl.map((el, eli) => {
              if (el.name !== "My Profile" || userName) {
                return (
                  <div className="mobileSingleNavFullDiv" key={eli}>
                    <div
                      className={`${
                        showMobileDropDown === el.name &&
                        el.childDropdown?.length > 0
                          ? "mobileSingleNavMainDivActive"
                          : "mobileSingleNavMainDiv"
                      }`}
                      onClick={() => {
                        // if (el.childDropdown?.length > 0) {
                        handleShowDropDown(el.name);
                        if (el.link) {
                          handleNavigate(el.link);
                        }
                        // }
                      }}
                    >
                      <div className="mobileSingleNavOnlyNameAndIcon">
                        <Icon
                          className="mobileSingleNavNameIcon"
                          icon={el.icon}
                        />
                        <span className="mobileSingleNavNameSpan">
                          {el.name}
                        </span>
                      </div>
                      {el.childDropdown?.length > 0 && (
                        <Icon
                          className="mobileSingleNavDropDownIcon"
                          icon="eva:arrow-left-fill"
                          // onClick={() => handleShowDropDown(el.name)}
                        />
                      )}
                    </div>
                    {showMobileDropDown === el.name &&
                      el.childDropdown?.length > 0 && (
                        <div className="mobileDropdownMainDiv">
                          {el.childDropdown.map((nel, neli) => {
                            // if()
                            return (
                              <div key={neli} className="mobileSingleDropDown">
                                <Icon
                                  className="mobileSingleNavDropDownIcon"
                                  icon={nel.icon}
                                  // onClick={() => handleShowDropDown(el.name)}
                                />
                                <span>{nel.name}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                );
              }
            })}
            {!userName && (
              <div className="mobileSinglesignInOutMainDiv">
                <div
                  className="mobileSinglesignInOutOnlyNameAndIcon"
                  onClick={() => handleNavigate("login")}
                >
                  <Icon className="signInOutIcon" icon="lets-icons:sign-in" />
                  <span className="signInOutName">Login</span>
                </div>
              </div>
            )}
            {!userName && (
              <div className="mobileSinglesignInOutMainDiv">
                <div
                  className="mobileSinglesignInOutOnlyNameAndIcon"
                  onClick={() => handleNavigate("signup")}
                >
                  <Icon className="signInOutIcon" icon="lets-icons:sign-in" />
                  <span className="signInOutName">Sign Up</span>
                </div>
              </div>
            )}
            {userName && (
              <div className="mobileSinglesignInOutMainDiv">
                <div
                  className="mobileSinglesignInOutOnlyNameAndIcon"
                  onClick={() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    window.location.href = "/";
                  }}
                >
                  <Icon className="signInOutIcon" icon="lets-icons:sign-in" />
                  <span className="signInOutName">Sign Out</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MobileWidthHeader;
