import React from "react";

export const SmallPopup = ({ children }) => {
  return (
    <div className="globalPopupMainDiv">
      <div className="globalPopupFormMainDiv center">
        <div className="globalPopupChildrenDiv">{children}</div>
      </div>
    </div>
  );
};
