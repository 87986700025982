import React, { useEffect, useRef, useState } from "react";
import "./timer.css";

export const CountDown = ({
  timeInMinute,
  timeInSecond,
  isSmallMobile = false,
  handleSubmit = () => {},
}) => {
  const [remainingTime, setRemainingTime] = useState(null);

  const examEndingTime = useRef(
    Date.now() + timeInMinute * 60000 + timeInSecond * 1000
  );

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (examEndingTime.current - Date.now() <= 0) {
        return handleSubmit();
      } else {
        setRemainingTime(examEndingTime.current - Date.now());
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [examEndingTime, handleSubmit]);

  return (
    <div
      className={`${
        isSmallMobile ? "countDownSpanForSmallMobile" : "countDownSpan"
      }`}
    >
      {Math.floor(remainingTime / 3600000) >= 10
        ? Math.floor(remainingTime / 3600000)
        : `0${Math.floor(remainingTime / 3600000)}`}
      :
      {Math.floor(remainingTime / 60000) % 60 >= 10
        ? Math.floor(remainingTime / 60000) % 60
        : `0${Math.floor(remainingTime / 60000) % 60}`}
      :
      {Math.floor(remainingTime / 1000) % 60 >= 10
        ? Math.floor(remainingTime / 1000) % 60
        : `0${Math.floor(remainingTime / 1000) % 60}`}
    </div>
  );
};

export const SingleQuestionTimeTakenTimer = ({
  currentQuestionNumber,
  answers,
  setAnswers,
}) => {
  const [oldQuestionNumber, setOldQuestionNumber] = useState(
    currentQuestionNumber
  );
  const [currentQuestionTimeTaken, setCurrentQuestionTimeTaken] = useState(
    currentQuestionNumber in answers
      ? "timeTaken" in answers[currentQuestionNumber]
        ? answers[currentQuestionNumber].timeTaken
        : 0
      : 0
  );
  const intervalRef = useRef(null);
  const [currentQuestionStartTime, setCurrentQuestionStartTime] = useState(
    currentQuestionNumber in answers
      ? "timeTaken" in answers[currentQuestionNumber]
        ? Date.now() - answers[currentQuestionNumber].timeTaken
        : Date.now()
      : Date.now()
  );
  // const currentQuestionStartTime = useRef(
  //   currentQuestionNumber in answers
  //     ? "timeTaken" in answers[currentQuestionNumber]
  //       ? Date.now() - answers[currentQuestionNumber].timeTaken
  //       : Date.now()
  //     : Date.now()
  // );

  useEffect(() => {
    if (oldQuestionNumber !== currentQuestionNumber) {
      clearInterval(intervalRef.current);

      setAnswers((prevValue) => {
        return {
          ...prevValue,
          [oldQuestionNumber]: {
            ...prevValue[oldQuestionNumber],
            timeTaken: currentQuestionTimeTaken,
          },
        };
      });

      setOldQuestionNumber(() => {
        return currentQuestionNumber;
      });

      setCurrentQuestionTimeTaken(() => {
        return currentQuestionNumber in answers
          ? "timeTaken" in answers[currentQuestionNumber]
            ? answers[currentQuestionNumber].timeTaken
            : 0
          : 0;
      });

      setCurrentQuestionStartTime(() => {
        return currentQuestionNumber in answers
          ? "timeTaken" in answers[currentQuestionNumber]
            ? Date.now() - answers[currentQuestionNumber].timeTaken
            : Date.now()
          : Date.now();
      });
    }
    intervalRef.current = setInterval(() => {
      setCurrentQuestionTimeTaken(() => {
        return Date.now() - currentQuestionStartTime;
      });
    }, 100);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [
    oldQuestionNumber,
    currentQuestionNumber,
    answers,
    currentQuestionStartTime,
    setAnswers,
    currentQuestionTimeTaken,
  ]);

  return (
    <span>
      {Math.floor(currentQuestionTimeTaken / 60000) % 60 >= 10
        ? Math.floor(currentQuestionTimeTaken / 60000) % 60
        : `0${Math.floor(currentQuestionTimeTaken / 60000) % 60}`}
      :
      {Math.floor(currentQuestionTimeTaken / 1000) % 60 >= 10
        ? Math.floor(currentQuestionTimeTaken / 1000) % 60
        : `0${Math.floor(currentQuestionTimeTaken / 1000) % 60}`}
    </span>
  );
  // return <span>{currentQuestionTimeTaken}</span>;
  // return <span>04:20</span>;
};
