import React, { useEffect, useState } from "react";
import ResultPieChart from "../../components/charts/ResultPieChart";
import Loader from "../../components/common/loader/Loader";
import { getFetch } from "../../functions/fetch/fetch.crud";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import MarkHalfPieChart from "../../components/charts/MarkHalfPieChart";

const Result = () => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const chartData = [
    { x: "A", y: 85 },
    { x: "B", y: 15 },
    //   { x: "C", y: 33 },
  ];
  const chartColors = ["rgba(0, 197, 6 ,1)", "rgba(255, 0, 0 ,0.5)"];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const result_id = searchParams.get("id");

    async function getResult() {
      try {
        const result = await getFetch(`result/dynamic/${result_id}`);
        console.log("result =", result);
        if (result.error) {
          toast.error(result.error);
          return;
        } else {
          // toast.
          setLoading(false);
          setResult(result.result);
          return;
        }
      } catch (error) {
        console.log("error while getting result.");
        return;
      }
    }

    if (loading) {
      getResult();
      return;
    }
  }, [loading, location]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        // <ResultPieChart />
        <>
          <MarkHalfPieChart
            PropertyValue={result.marks}
            PropertyName={"Mark"}
            // isPercentage
          />
          <MarkHalfPieChart
            PropertyValue={result.accuracy}
            PropertyName={"Accuracy"}
            // isPercentage
          />
          <ResultPieChart />
        </>
      )}
    </div>
  );
};

export default Result;
