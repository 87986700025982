// import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/common/header/Header";
import { useLocation } from "react-router-dom";
import Home from "../pages/Home/Home";

const Layout = () => {
  const location = useLocation();
  // console.log("we are in layout.");

  return (
    <>
      <Header />
      <main>
        <Outlet /> {/* Placeholder for route content */}
      </main>
      {location.pathname === "/" && <Home />}
    </>
  );
};

export default Layout;
