import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const loggedUserSlice = createSlice({
  name: "loggedUser",
  initialState,
  reducers: {
    logIn: (state, action) => {
      state.value = action.payload;
    },
    logOut: (state) => {
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logIn, logOut } = loggedUserSlice.actions;

export default loggedUserSlice.reducer;
