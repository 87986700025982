import "./App.css";
import "./style/dimensions.css";
import "./style/table.css";
import "./style/flex.css";
import "./style/design.css";
import { store } from "./redux/Store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/Router";

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
